export const FETCH_SUBSCRIPTION_FEATURES_START = "FETCH_SUBSCRIPTION_FEATURES_START";
export const FETCH_SUBSCRIPTION_FEATURES_RECIEVES = "FETCH_SUBSCRIPTION_FEATURES_RECIEVES";
export const FETCH_SUBSCRIPTION_FEATURES_END = "FETCH_SUBSCRIPTION_FEATURES_END";
export const CREATE_SUBSCRIPTION_FEATURE_START = "CREATE_SUBSCRIPTION_FEATURE_START";
export const CREATE_SUBSCRIPTION_FEATURE_RECIEVES = "CREATE_SUBSCRIPTION_FEATURE_RECIEVES";
export const CREATE_SUBSCRIPTION_FEATURE_END = "CREATE_SUBSCRIPTION_FEATURE_END";
export const UPDATE_SUBSCRIPTION_FEATURE_START = "UPDATE_SUBSCRIPTION_FEATURE_START";
export const UPDATE_SUBSCRIPTION_FEATURE_RECIEVES = "UPDATE_SUBSCRIPTION_FEATURE_RECIEVES";
export const UPDATE_SUBSCRIPTION_FEATURE_END = "UPDATE_SUBSCRIPTION_FEATURE_END";
export const DELETE_SUBSCRIPTION_FEATURE_START = "DELETE_SUBSCRIPTION_FEATURE_START";
export const DELETE_SUBSCRIPTION_FEATURE_RECIEVES = "DELETE_SUBSCRIPTION_FEATURE_RECIEVES";
export const DELETE_SUBSCRIPTION_FEATURE_END = "DELETE_SUBSCRIPTION_FEATURE_END";
