import { creditFeaturePolicyApi } from "src/__Api__/creditFeaturePoliciesApi";
import {
CREATE_POLICY_FEATURE_END,
CREATE_POLICY_FEATURE_RECIEVES,
CREATE_POLICY_FEATURE_START,
UPDATE_POLICY_FEATURE_END,
UPDATE_POLICY_FEATURE_RECIEVES,
UPDATE_POLICY_FEATURE_START,
DELETE_POLICY_FEATURE_END,
DELETE_POLICY_FEATURE_RECIEVES,
DELETE_POLICY_FEATURE_START,
FETCH_POLICY_FEATURES_END,
FETCH_POLICY_FEATURES_RECIEVES,
FETCH_POLICY_FEATURES_START
} from "./action-types";
import { isString } from "lodash";

export const createPolicyFeature = (body: any, setIsLoading?: any) => async (dispatch) => {
	try {
		// setIsLoading(true)
		dispatch({ type: CREATE_POLICY_FEATURE_START });

		const result = await creditFeaturePolicyApi.createPolicyFeature(body);
		// setIsLoading(false);
		if (result && result?.id) {
			dispatch({
				type: CREATE_POLICY_FEATURE_END,
				data: result,
				error: "",
			});			
		} else {
			dispatch({
				type: CREATE_POLICY_FEATURE_RECIEVES,
				data: [],
				error: result,
			});
		}
	} catch (error) {
		dispatch({
			type: CREATE_POLICY_FEATURE_END,
			data: [],
			error,
		});
	}
}

export const getPolicyFeatures = (id: string) => async (dispatch) => {	
	try {
		dispatch({ type: FETCH_POLICY_FEATURES_START });

		const result = await creditFeaturePolicyApi.getPolicyFeatures(id);
		if (isString(result)) {
			dispatch({
				type: FETCH_POLICY_FEATURES_END,
				data: [],
				error: result,
			});
		} else {
			dispatch({
				type: FETCH_POLICY_FEATURES_RECIEVES,
				data: result,
				error: "",
			});
		}
	} catch (error) {
		dispatch({
			type: FETCH_POLICY_FEATURES_END,
			data: [],
			error,
		});
	}
}

export const deletePolicyFeature = (id: string) => async (dispatch) => {	
	try {
		dispatch({ type: DELETE_POLICY_FEATURE_START });

		const result = await creditFeaturePolicyApi.deletePolicyFeature(id);
		if (isString(result)) {
			dispatch({
				type: DELETE_POLICY_FEATURE_END,
				data: [],
				error: result,
			});
		} else {
			dispatch({
				type: DELETE_POLICY_FEATURE_RECIEVES,
				data: result,
				error: "",
			});
		}
	} catch (error) {
		dispatch({
			type: DELETE_POLICY_FEATURE_END,
			data: [],
			error,
		});
	}
}

export const updatePolicyFeature = (body: any, id: string) => async (dispatch) => {	
	try {
		dispatch({ type: UPDATE_POLICY_FEATURE_START });

		const result = await creditFeaturePolicyApi.updatePolicyFeature(body, id);
		if (isString(result)) {
			dispatch({
				type: UPDATE_POLICY_FEATURE_END,
				data: [],
				error: result,
			});
		} else {
			dispatch({
				type: UPDATE_POLICY_FEATURE_RECIEVES,
				data: result,
				error: "",
			});
		}
	} catch (error) {
		dispatch({
			type: UPDATE_POLICY_FEATURE_END,
			data: [],
			error,
		});
	}
}