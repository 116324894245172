import { Box, Link, Switch, TableCell, } from "@mui/material";

import IconButton from "@mui/material/IconButton";
import Trash from "src/icons/Trash";
import PencilAlt from "src/icons/PencilAlt";
import { Link as RouterLink} from "react-router-dom";

import { alpha, styled } from "@mui/material/styles";

const ActionColumn = (props) => {
  
  const {
    creditFeaturePolicy,
    handleDelete ,
    handleEdit,
    handleActivate,
    handleDeactivate
  } = props;

  const GreenSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#76d14d",
      "&:hover": {
        backgroundColor: alpha("#76d14d", theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#76d14d",
    },
  }));

  return (
    <TableCell>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{ fontSize: "14px", cursor: "pointer" }}
          onClick={() => handleDelete(creditFeaturePolicy?.id)}
        >
          <IconButton>
            <Trash color="error" />
          </IconButton>
        </Box>
        <Link
          component={RouterLink}
          to={
            creditFeaturePolicy?.status === "I"
                ? `/credit-feature-policy-form?id=${creditFeaturePolicy?.id}`     
              : "/credit-feature-policy"
          }
          onClick={() => handleEdit(creditFeaturePolicy?.status,creditFeaturePolicy?.id )}
          variant="subtitle2"
          sx={{ fontSize: "14px" }}
        >
          <IconButton>
            <PencilAlt  />
          </IconButton>
        </Link>

        <Box
          sx={{ fontSize: "14px", cursor: "pointer" }}
          onClick={()=>creditFeaturePolicy?.status === "I" ? handleActivate(creditFeaturePolicy?.id) : handleDeactivate(creditFeaturePolicy?.id)}
        >
          {creditFeaturePolicy?.status === "I" ? (
            <GreenSwitch 
             />
          ) : (
            <GreenSwitch defaultChecked />
          )}
        </Box>
      </Box>
    </TableCell>
  );
};

export default ActionColumn;
