import { Box, TableCell, } from "@mui/material";

import IconButton from "@mui/material/IconButton";
import Trash from "src/icons/Trash";
import PencilAlt from "src/icons/PencilAlt";

const ActionColumn = (props) => {
  
  const {
    botTheme,
    handleDeleteModal,
    handleUpdateModal
  } = props;


  return (
    <TableCell>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{ fontSize: "14px", cursor: "pointer" }}
          onClick={() => handleDeleteModal(botTheme)}
        >
          <IconButton>
            <Trash color="error" />
          </IconButton>
        </Box>
        <Box
          sx={{ fontSize: "14px", cursor: "pointer" }}
          onClick={() => handleUpdateModal(botTheme)}
        >
          <IconButton>
            <PencilAlt />
          </IconButton>
        </Box>
      </Box>
    </TableCell>
  );
};

export default ActionColumn;
