import {
  Grid,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  CircularProgress,
} from "@mui/material";

import ActionColumn from "./ActionColumn";

const SubscriptionManagementTable = (props) => {
  const {
    subscriptions,
    handleSubscriptionStatus,
    handleDeleteSubscription,
    isFreePlanActive,
    loadingStates,
  } = props;

  return (
    <Container>
      <Grid position="relative" height="" sx={{ py: 4 }}>
        <Grid item>
          <Grid>
            <TableContainer
              sx={{
                maxHeight: 460,
                border: "none",
                boxShadow: "none",
                backgroundColor: "transparent !important",
              }}
              component={Paper}
            >
              <Table
                stickyHeader
                sx={{ minWidth: 650 }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left" sx={{ color: "#919eab" }}>
                      Title
                    </TableCell>
                    <TableCell align="left" sx={{ color: "#919eab" }}>
                      Plan Type
                    </TableCell>
                    <TableCell align="left" sx={{ color: "#919eab" }}>
                      Display On Main
                    </TableCell>
                    <TableCell align="left" sx={{ color: "#919eab" }}>
                      Price
                    </TableCell>
                    <TableCell align="left" sx={{ color: "#919eab" }}>
                      Status
                    </TableCell>
                    <TableCell align="center" sx={{ color: "#919eab" }}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!loadingStates?.fetchAllPlans ? (
                    subscriptions?.length ? (
                      subscriptions?.map((subscription) => {
                        return (
                          <TableRow
                            key={subscription?.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {subscription?.title}
                            </TableCell>
                            <TableCell>{subscription?.type}</TableCell>
                            <TableCell>
                              {subscription?.displayOnMain ? "true" : "false"}
                            </TableCell>
                            <TableCell>{subscription?.price}</TableCell>
                            <TableCell>
                              {subscription?.status === "I"
                                ? "Inactive"
                                : "Active"}
                            </TableCell>
                            <ActionColumn
                              subscription={subscription}
                              handleSubscriptionStatus={
                                handleSubscriptionStatus
                              }
                              handleDeleteSubscription={
                                handleDeleteSubscription
                              }
                              isFreePlanActive={isFreePlanActive}
                            />
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          colSpan={5}
                          align="center"
                        >
                          No Subscription Plans
                        </TableCell>
                      </TableRow>
                    )
                  ) : (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        colSpan={5}
                        align="center"
                      >
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SubscriptionManagementTable;
