import { combineReducers } from "@reduxjs/toolkit";
import {
	FETCH_SUBSCRIPTION_FEATURES_START,
	FETCH_SUBSCRIPTION_FEATURES_RECIEVES,
	FETCH_SUBSCRIPTION_FEATURES_END,
	CREATE_SUBSCRIPTION_FEATURE_START,
	CREATE_SUBSCRIPTION_FEATURE_RECIEVES,
	CREATE_SUBSCRIPTION_FEATURE_END,
} from "./action-types";

export const SubscriptionFeature = (
	state: { loading: boolean; data: any } = {
		loading: false,
		data: { data: [] },
	},
	{ type, data, error }
) => {
	switch (type) {
		case FETCH_SUBSCRIPTION_FEATURES_START: {
			return {
				loading: true,
				data: [],
				error: "",
			};
		}
		case FETCH_SUBSCRIPTION_FEATURES_RECIEVES: {
			return {
				loading: false,
				data,
				error: "",
			};
		}
		case FETCH_SUBSCRIPTION_FEATURES_END: {
			return {
				loading: false,
				data: [],
				error,
			};
		}
		default:
			return state;
	}
};

export const CreateSubscriptionFeature = (
	state: { loading: boolean; data: any } = {
		loading: false,
		data: { data: [] },
	},
	{ type, data, error }
) => {
	switch (type) {
		case CREATE_SUBSCRIPTION_FEATURE_START: {
			return {
				loading: true,
				data: [],
				error: "",
			};
		}
		case CREATE_SUBSCRIPTION_FEATURE_RECIEVES: {
			return {
				loading: false,
				data,
				error: "",
			};
		}
		case CREATE_SUBSCRIPTION_FEATURE_END: {
			return {
				loading: false,
				data: [],
				error,
			};
		}
		default:
			return state;
	}
};

export default combineReducers({
	SubscriptionFeature,
	CreateSubscriptionFeature
});