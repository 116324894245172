// import { createTheme, responsiveFontSizes } from '@mui/material/styles';

import { createTheme, Direction, responsiveFontSizes, Theme, ThemeOptions } from '@mui/material';
import { THEMES } from '../constants';
import { darkShadows } from './shadows';

const apexColors = {
  primaryBlue: '#0992e2',
  backgroundBlue: '#01173b',
  backgroundBlue2: 'rgba(35, 35, 40, 1)',
  backgroundDark: 'rgba(25, 25, 30, 1)',

  tradingGreen: '#1aff00',
  tradingGreenDark: '#1aff0020',
  tradingGreenLight: '#75e269',

  tradingRed: '#ff0037',
  tradingRedDark: '#ff003720',
  tradingRedLight: '#cc5872',

  popoverBackground: '#01183b',
  componentBackground: 'rgba(25, 25, 30, 1)',
  cardHeadBackground: 'rgba(0, 107, 255, 0.1)',
  tableHeaderBackground: 'rgba(0, 107, 255, 0.1)',
  borderColorBase: 'rgba(255, 255, 255, 0.1)',
  borderColorSplit: 'rgba(255, 255, 255, 0.05)',
  primary7: 'rgba(255, 255, 255, 0.85)',
  tabsActiveColor: 'rgba(255, 255, 255, 0.85)',
  tabsHoverColor: 'rgba(255, 255, 255, 0.75)',
  tabsHighlightColor: 'rgba(255, 255, 255, 0.85)',
  tableRowHoverBackground: 'rgba(0, 0, 0, 0.1)',
  dropdownMenuBackground: '#002454',
  selectDropdownBackground: '#002454',
  selectSelectionItemBackground: 'rgba(43, 83, 176, 0.3)',
  selectItemSelectedBackground: '#0e52b0',
  menuDarkItemActiveBackground: 'transparent',
  menuDarkHighlightColor: '#ffffff',
  linkColor: '#0070ff',
  linkActiveColor: '#1890ff',
  linkHoverColor: '#1890ff',
  cardSkeletonBackground: '#1890ff',

  headerBackgroundColor: 'rgba(25, 25, 30, 1)',
};

const borders = {
  lightEdge: '1px solid rgba(255,255,255,0.2)',
}

interface ThemeConfig {
  direction?: Direction;
  responsiveFontSizes?: boolean;
  roundedCorners?: boolean;
  theme?: string;
}

const baseOptions: ThemeOptions = {
  direction: 'ltr',
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          border: borders.lightEdge,
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            opacity: 0.86,
            color: '#42526e'
          },
        },
        root: {
          borderRadius: '0 !important',
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            opacity: 0.86,
            color: '#42526e'
          },
        },
        root: {
          borderRadius: 0,
        }
      }
    },
    MuiTooltip: {
      // tooltip: {
        styleOverrides: {
          tooltip: {
            color: '#ffffff',
            backgroundColor: apexColors.backgroundDark,
            borderRadius: 0,
            border: borders.lightEdge,
          },
        },
      // }
    },
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: '75%',
          width: '75%'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 0,
          // height: '400px',
        },
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box'
        },
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          height: '100%',
          width: '100%'
        },
        body: {
          height: '100%'
        },
        '#root': {
          height: '100%'
        },
        '#nprogress .bar': {
          zIndex: '2000 !important'
        }
      }
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6'
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden'
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: '16px'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          backgroundColor: apexColors.backgroundBlue2,
          border: borders.lightEdge,
          borderRadius: 0,
        },
      }
    }
  },
  typography: {
    button: {
      fontWeight: 600
    },
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    h1: {
      fontWeight: 600,
      fontSize: '3.5rem'
    },
    h2: {
      fontWeight: 600,
      fontSize: '3rem'
    },
    h3: {
      fontWeight: 600,
      fontSize: '2.25rem'
    },
    h4: {
      fontWeight: 600,
      fontSize: '2rem'
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.5rem'
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.125rem'
    },
    overline: {
      fontWeight: 600
    }
  }
};

const themesOptions: Record<string, ThemeOptions> = {
  [THEMES.DARK]: {
    components: {
      ...baseOptions.components,
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)'
          }
        }
      }
    },
    palette: {
      background: {
        default: apexColors.backgroundDark,
        paper: apexColors.componentBackground,
      },
      divider: apexColors.borderColorSplit,
      error: {
        contrastText: '#ffffff',
        main: '#f44336'
      },
      mode: 'dark',
      primary: {
        contrastText: '#ffffff',
        main: apexColors.primaryBlue,
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50'
      },
      text: {
        primary: '#ffffff',
        secondary: '#919eab'
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800'
      },
      /*
       * The below would cause typescript complaints. We do have a solution for this,
       * but it's unlikely that you'll need these colors yet. We'll add typescript files
       * to fix this issue when we need to.
       */
      // tradingGreen: {
      //   light: apexColors.tradingGreenLight,
      //   dark: apexColors.tradingGreenDark,
      //   contrastText: apexColors.tradingGreenLight,
      //   main: apexColors.tradingGreen
      // },
      // tradingRed: {
      //   light: apexColors.tradingRedLight,
      //   dark: apexColors.tradingRedDark,
      //   contrastText: apexColors.tradingRedLight,
      //   main: apexColors.tradingRed
      // },
      // grids: {
      //   highlightedRowBackground: 'rgba(14, 82, 176, 0.1)',
      //   highlightedBorder: '1px solid rgba(14, 82, 176, 0.6)',
      // }
    },
    shadows: darkShadows
  },
};

export const createCustomTheme = (config: ThemeConfig = {}): Theme => {
  // let themeOptions = themesOptions[config.theme];
  let themeOptions = themesOptions[THEMES.DARK];

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    themeOptions = themesOptions[THEMES.DARK];
  }

  config.roundedCorners = false;

  let theme = createTheme(
    {
      ...baseOptions,
      ...themeOptions,
      ...{
        direction: config.direction
      }
    }
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};