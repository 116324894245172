import { Helmet } from "react-helmet-async";
import { Container, Grid, Box, Typography, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import TabsComponent from "src/components/dashboard/creditFeaturePolicy/TabsComponent";
import BasicInformationTab from "src/components/dashboard/creditFeaturePolicy/BasicInformationTab";
import ManageFeaturesTab from "src/components/dashboard/creditFeaturePolicy/ManageFeatureTab";
import SettingTab from "src/components/dashboard/creditFeaturePolicy/SettingTab";
import useCreditsFeaturePolicy from "src/hooks/useCreditsFeaturePolicy";

const CreateCreditFeaturePolicy = () => {
  const {
    currentTab,
    isTabsDisable,
    isUpdatePolicy,
    policyFeatures,
    handleTabChange,
    isUpdateFeature,
    getDisplayValue,
    handleFeatureEdit,
    policyFeatureInput,
    creditFeaturePolicy,
    creditFeaturePolicyInput,
    handlePolicyFeatureInput,
    handleUpdatePolicyFeature,
    handleDeletePolicyFeature,
    handleCreatePolicyFeature,
    handleCreditFeaturePolicyInput,
    handleCreateCreditFeaturePolicy,
    handleUpdateCreditFeaturePolicy,
    handleActivateCreditFeaturePolicy,
  } = useCreditsFeaturePolicy();

  return (
    <>
      <Helmet>
        <title>APEXE3 Revolutions</title>
      </Helmet>
      <Container>
        <Grid position="relative" height="100vh" sx={{ py: 4, mt: 10 }}>
          <Button
            sx={{ mb: 2 }}
            onClick={() => window.location.replace("/credit-feature-policy")}
          >
            <ArrowBackIcon />
          </Button>
          <Grid item>
            <Typography variant="h4" component="h2" mb={3} ml={3}>
              {`${isUpdatePolicy ? "Update" : "Create"} Credit Feature Policy`}
            </Typography>
            <Box>
              <TabsComponent
                currentTab={currentTab}
                handleTabChange={handleTabChange}
                disableTabs={isUpdatePolicy ? false : isTabsDisable}
              />
              <Box sx={{ padding: 2 }}>
                {currentTab === "Basic Information" && (
                  <BasicInformationTab
                    creditFeaturePolicyInput={creditFeaturePolicyInput}
                    handleCreditFeaturePolicyInput={
                      handleCreditFeaturePolicyInput
                    }
                    handleCreateCreditFeaturePolicy={
                      handleCreateCreditFeaturePolicy
                    }
                    isUpdatePolicy={isUpdatePolicy}
                    creditFeaturePolicy={creditFeaturePolicy}
                    handleUpdateCreditFeaturePolicy={
                      handleUpdateCreditFeaturePolicy
                    }
                  />
                )}
                {currentTab === "Manage Features" && (
                  <ManageFeaturesTab
                    policyFeatureInput={policyFeatureInput}
                    handlePolicyFeatureInput={handlePolicyFeatureInput}
                    getDisplayValue={getDisplayValue}
                    handleCreatePolicyFeature={handleCreatePolicyFeature}
                    policyFeatures={policyFeatures}
                    handleDeletePolicyFeature={handleDeletePolicyFeature}
                    handleFeatureEdit={handleFeatureEdit}
                    handleUpdatePolicyFeature={handleUpdatePolicyFeature}
                    isUpdateFeature={isUpdateFeature}
                  />
                )}
                {currentTab === "Setting" && (
                  <SettingTab
                    handleActive={() => {
                      handleActivateCreditFeaturePolicy(
                        creditFeaturePolicy?.id
                      );
                    }}
                    description={creditFeaturePolicy?.description}
                  />
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CreateCreditFeaturePolicy;
