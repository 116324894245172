import {
  Grid,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  Switch,
  Box,
  CircularProgress,
} from "@mui/material";

import ActionColumn from "./ActionColumn";

import { alpha, styled } from "@mui/material/styles";
import { toast } from "react-hot-toast";

const CreditFeaturePolicyTable = (props) => {
  const {
    loadingStates,
    handleSubscriptionStatus,
    isFreePlanActive,
    creditFeaturePolicies,
    handleDeleteCreditFeaturePolicy,
    handleEdit,
    handleActivateCreditFeaturePolicy,
    handleDeactivateCreditFeaturePolicy,
    handleSetCreditFeaturePolicyAsDefault,
  } = props;

  const GreenSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#76d14d",
      "&:hover": {
        backgroundColor: alpha("#76d14d", theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#76d14d",
    },
  }));

  return (
    <Container>
      <Grid position="relative" height="" sx={{ py: 4 }}>
        <Grid item>
          <Grid>
            <TableContainer
              sx={{
                maxHeight: 460,
                border: "none",
                boxShadow: "none",
                backgroundColor: "transparent !important",
              }}
              component={Paper}
            >
              <Table
                stickyHeader
                sx={{ minWidth: 650 }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left" sx={{ color: "#919eab" }}>
                      Title
                    </TableCell>
                    <TableCell align="left" sx={{ color: "#919eab" }}>
                      Status
                    </TableCell>
                    <TableCell align="center" sx={{ color: "#919eab" }}>
                      Set As Default
                    </TableCell>
                    <TableCell align="center" sx={{ color: "#919eab" }}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
					!loadingStates.fetchAllPolicies ?
				  creditFeaturePolicies?.length ? (
                    creditFeaturePolicies?.map((creditFeaturePolicy) => {
                      return (
                        <TableRow
                          key={creditFeaturePolicy?.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {creditFeaturePolicy?.title}
                          </TableCell>
                          <TableCell>
                            {creditFeaturePolicy?.status === "I"
                              ? "Inactive"
                              : "Active"}
                          </TableCell>

                          <TableCell align="center">
                            <Box
                              onClick={() => {
                                if (creditFeaturePolicy?.status !== "I") {
                                  handleSetCreditFeaturePolicyAsDefault(
                                    creditFeaturePolicy?.id
                                  );
                                } else {
                                  toast.error("Activate your policy first", {
                                    style: {
                                      borderRadius: "10px",
                                      background: "#333",
                                      color: "#fff",
                                    },
                                  });
                                }
                              }}
                            >
                              {!creditFeaturePolicy?.isDefault ? (
                                <GreenSwitch
                                  disabled={creditFeaturePolicy?.status === "I"}
                                />
                              ) : (
                                <GreenSwitch defaultChecked />
                              )}
                            </Box>
                          </TableCell>

                          <ActionColumn
                            subscription={creditFeaturePolicy}
                            handleSubscriptionStatus={handleSubscriptionStatus}
                            handleDelete={handleDeleteCreditFeaturePolicy}
                            isFreePlanActive={isFreePlanActive}
                            creditFeaturePolicy={creditFeaturePolicy}
                            handleEdit={handleEdit}
                            handleActivate={handleActivateCreditFeaturePolicy}
                            handleDeactivate={
                              handleDeactivateCreditFeaturePolicy
                            }
                          />
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        colSpan={5}
                        align="center"
                      >
                        No Credit Feature Policies
                      </TableCell>
                    </TableRow>
                  ) :
				  <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        colSpan={6}
                        align="center"
                      >
                       <CircularProgress/>
                      </TableCell>
                    </TableRow>
				  }
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CreditFeaturePolicyTable;
