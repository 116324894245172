import axios from "axios";
import { getAccessToken } from "src/utils/token";
import toast from "react-hot-toast";
import { subscriptionConfig } from "src/config";
import { CreateCreditFeaturePolicy, CreatePolicyFeature } from "src/types/creditFeaturePolicy";

class CreditFeaturePolicyApi {

	async getAllCreditFeaturePolicies() {
		
		try {
			const { baseUrl } = subscriptionConfig();
			const token = getAccessToken("accessToken");
			const response = await axios.get(`${baseUrl}/credit-feature-policies`, {
				headers: {
					Authorization: `Bearer ${token}`,
					"content-type": "application/json",
				},
			});

			if (response != null && response.data) {
				return response.data;
			} else {
				toast.success("No data recieved",{
					style: {
						borderRadius: '10px',
						background: '#333',
						color: '#fff',
					},
					});
				throw new Error("No data recieved");
			}
		} catch (e) {
			toast.error(e?.response?.data?.message || "Something went wrong",{
					style: {
						borderRadius: '10px',
						background: '#333',
						color: '#fff',
					},
					});
			return e?.response?.data?.message || "Something went wrong";
		}

	};

	async getCreditFeaturePolicy(id: string) {
		
		try {
			const { baseUrl } = subscriptionConfig();
			const token = getAccessToken("accessToken");
			const response = await axios.post(`${baseUrl}/credit-feature-policies/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`,
					"content-type": "application/json",
				},
			});

			if (response != null && response.data) {
				return response.data;
			} else {
				toast.success("No data recieved",{
					style: {
						borderRadius: '10px',
						background: '#333',
						color: '#fff',
					},
					});
				throw new Error("No data recieved");
			}
		} catch (e) {
			toast.error(e?.response?.data?.message || "Something went wrong",{
					style: {
						borderRadius: '10px',
						background: '#333',
						color: '#fff',
					},
					});
			return e?.response?.data?.message || "Something went wrong";
		}

	};

	async createCreditFeaturePolicy(body: CreateCreditFeaturePolicy) {
		try {
			const { baseUrl } = subscriptionConfig();
			const token = getAccessToken("accessToken");
			const response = await axios.post(`${baseUrl}/credit-feature-policies`, body, {
				headers: {
					Authorization: `Bearer ${token}`,
					"content-type": "application/json",
				},
			});

			if (response != null && response.data) {
				toast.success("Credit Feature Policy Created Successfully",{
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  } );
				return response.data;
			} else {
				toast.success("No data recieved",{
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
				throw new Error("No data recieved");
			}
		} catch (e) {
			toast.error(e?.response?.data?.message || "Something went wrong",{
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
			return e?.response?.data?.message || "Something went wrong";
		}

	};

	async updateCreditFeaturePolicy(body: CreateCreditFeaturePolicy, id: string) {
		try {			
			const { baseUrl } = subscriptionConfig();
			const token = getAccessToken("accessToken");
			const response = await axios.patch(`${baseUrl}/credit-feature-policies/${id}`, body, {
				headers: {
					Authorization: `Bearer ${token}`,
					"content-type": "application/json",
				},
			});

			if (response != null && response.data) {
				toast.success("Credit Feature Policy Updated Successfully",{
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
				return response.data;
			} else {
				toast.success("No data recieved",{
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
				throw new Error("No data recieved");
			}
		} catch (e) {
			toast.error(e?.response?.data?.message || "Something went wrong",{
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
			return e?.response?.data?.message || "Something went wrong";
		}

	};


	async deleteCreditFeaturePolicy(id: string) {
		try {
			const { baseUrl } = subscriptionConfig();
			const token = getAccessToken("accessToken");
			const response = await axios.delete(`${baseUrl}/credit-feature-policies/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`,
					"content-type": "application/json",
				},
			});

			if (response != null && response.data) {
				toast.success("Credit Feature Policy Deleted Successfully",{
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
				return response.data;
			} else {
				toast.success("No data recieved",{
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
				throw new Error("No data recieved");
			}
		} catch (e) {
			toast.error(e?.response?.data?.message || "Something went wrong",{
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
			return e?.response?.data?.message || "Something went wrong";
		}

	};

	async activateCreditFeaturePolicy(id: string) {
		
		try {
			const { baseUrl } = subscriptionConfig();
			const token = getAccessToken("accessToken");
			const response = await axios.post(`${baseUrl}/credit-feature-policies/${id}/activate`, {
				headers: {
					Authorization: `Bearer ${token}`,
					"content-type": "application/json",
				},
			});

			if (response != null && response.data) {
				return response.data;
			} else {
				toast.success("No data recieved",{
					style: {
						borderRadius: '10px',
						background: '#333',
						color: '#fff',
					},
					});
				throw new Error("No data recieved");
			}
		} catch (e) {
			toast.error(e?.response?.data?.message || "Something went wrong",{
					style: {
						borderRadius: '10px',
						background: '#333',
						color: '#fff',
					},
					});
			return e?.response?.data?.message || "Something went wrong";
		}

	};

	async deactivateCreditFeaturePolicy(id: string) {
		
		try {
			const { baseUrl } = subscriptionConfig();
			const token = getAccessToken("accessToken");
			const response = await axios.post(`${baseUrl}/credit-feature-policies/${id}/deactivate`, {
				headers: {
					Authorization: `Bearer ${token}`,
					"content-type": "application/json",
				},
			});

			if (response != null && response.data) {
				return response.data;
			} else {
				toast.success("No data recieved",{
					style: {
						borderRadius: '10px',
						background: '#333',
						color: '#fff',
					},
					});
				throw new Error("No data recieved");
			}
		} catch (e) {
			toast.error(e?.response?.data?.message || "Something went wrong",{
					style: {
						borderRadius: '10px',
						background: '#333',
						color: '#fff',
					},
					});
			return e?.response?.data?.message || "Something went wrong";
		}

	};

	async setCreditFeaturePolicyAsDeafult(id: string) {
		
		try {
			const { baseUrl } = subscriptionConfig();
			const token = getAccessToken("accessToken");
			const response = await axios.post(`${baseUrl}/credit-feature-policies/${id}/setDefault`, {
				headers: {
					Authorization: `Bearer ${token}`,
					"content-type": "application/json",
				},
			});

			if (response != null && response.data) {
				return response.data;
			} else {
				toast.success("No data recieved",{
					style: {
						borderRadius: '10px',
						background: '#333',
						color: '#fff',
					},
					});
				throw new Error("No data recieved");
			}
		} catch (e) {
			toast.error(e?.response?.data?.message || "Something went wrong",{
					style: {
						borderRadius: '10px',
						background: '#333',
						color: '#fff',
					},
					});
			return e?.response?.data?.message || "Something went wrong";
		}

	};

	async createPolicyFeature(body: CreatePolicyFeature) {
		try {
			const { baseUrl } = subscriptionConfig();
			const token = getAccessToken("accessToken");
			const response = await axios.post(`${baseUrl}/credit-feature-policies-detail`, body, {
				headers: {
					Authorization: `Bearer ${token}`,
					"content-type": "application/json",
				},
			});

			if (response != null && response.data) {
				toast.success("Credit Feature Created Successfully",{
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  } );
				return response.data;
			} else {
				toast.success("No data recieved",{
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
				throw new Error("No data recieved");
			}
		} catch (e) {
			toast.error(e?.response?.data?.message || "Something went wrong",{
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
			return e?.response?.data?.message || "Something went wrong";
		}

	};

	async updatePolicyFeature(body: CreatePolicyFeature, featureId: string) {
		try {
			const { baseUrl } = subscriptionConfig();
			const token = getAccessToken("accessToken");
			const response = await axios.patch(`${baseUrl}/credit-feature-policies-detail/${featureId}`, body, {
				headers: {
					Authorization: `Bearer ${token}`,
					"content-type": "application/json",
				},
			});

			if (response != null && response.data) {
				toast.success("Credit Feature Updated Successfully",{
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  } );
				return response.data;
			} else {
				toast.success("No data recieved",{
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
				throw new Error("No data recieved");
			}
		} catch (e) {
			toast.error(e?.response?.data?.message || "Something went wrong",{
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
			return e?.response?.data?.message || "Something went wrong";
		}

	};

	async deletePolicyFeature(featureId: string) {
		try {
			const { baseUrl } = subscriptionConfig();
			const token = getAccessToken("accessToken");
			const response = await axios.delete(`${baseUrl}/credit-feature-policies-detail/${featureId}`, {
				headers: {
					Authorization: `Bearer ${token}`,
					"content-type": "application/json",
				},
			});

			if (response != null && response.data) {
				toast.success("Credit Feature Deleted Successfully",{
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  } );
				return response.data;
			} else {
				toast.success("No data recieved",{
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
				throw new Error("No data recieved");
			}
		} catch (e) {
			toast.error(e?.response?.data?.message || "Something went wrong",{
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
			return e?.response?.data?.message || "Something went wrong";
		}

	};


	async getPolicyFeatures(policyId: string) {
		
		try {
			const { baseUrl } = subscriptionConfig();
			const token = getAccessToken("accessToken");
			const response = await axios.get(`${baseUrl}/credit-feature-policies-detail/${policyId}`, {
				headers: {
					Authorization: `Bearer ${token}`,
					"content-type": "application/json",
				},
			});

			if (response != null && response.data) {
				return response.data;
			} else {
				toast.success("No data recieved",{
					style: {
						borderRadius: '10px',
						background: '#333',
						color: '#fff',
					},
					});
				throw new Error("No data recieved");
			}
		} catch (e) {
			toast.error(e?.response?.data?.message || "Something went wrong",{
					style: {
						borderRadius: '10px',
						background: '#333',
						color: '#fff',
					},
					});
			return e?.response?.data?.message || "Something went wrong";
		}

	};


};

export const creditFeaturePolicyApi = new CreditFeaturePolicyApi();
