export const FETCH_SUBSCRIPTIONS_LIST_START = "FETCH_SUBSCRIPTIONS_LIST_START";
export const FETCH_SUBSCRIPTIONS_LIST_RECIEVES = "FETCH_SUBSCRIPTIONS_LIST_RECIEVES";
export const FETCH_SUBSCRIPTIONS_LIST_END = "FETCH_SUBSCRIPTIONS_LIST_END";
export const CREATE_SUBSCRIPTIONS_START = "CREATE_SUBSCRIPTIONS_START";
export const CREATE_SUBSCRIPTIONS_RECIEVES = "CREATE_SUBSCRIPTIONS_RECIEVES";
export const CREATE_SUBSCRIPTIONS_END = "CREATE_SUBSCRIPTIONS_END";
export const UPDATE_SUBSCRIPTIONS_START = "UPDATE_SUBSCRIPTIONS_START";
export const UPDATE_SUBSCRIPTIONS_RECIEVES = "UPDATE_SUBSCRIPTIONS_RECIEVES";
export const UPDATE_SUBSCRIPTIONS_END = "UPDATE_SUBSCRIPTIONS_END";
export const DELETE_SUBSCRIPTIONS_START = "DELETE_SUBSCRIPTIONS_START";
export const DELETE_SUBSCRIPTIONS_RECIEVES = "DELETE_SUBSCRIPTIONS_RECIEVES";
export const DELETE_SUBSCRIPTIONS_END = "DELETE_SUBSCRIPTIONS_END";
export const Activate_SUBSCRIPTIONS_START = "Activate_SUBSCRIPTIONS_START";
export const Activate_SUBSCRIPTIONS_RECIEVES = "Activate_SUBSCRIPTIONS_RECIEVES";
export const Activate_SUBSCRIPTIONS_END = "Activate_SUBSCRIPTIONS_END";
export const Deactivate_SUBSCRIPTIONS_START = "Deactivate_SUBSCRIPTIONS_START";
export const Deactivate_SUBSCRIPTIONS_RECIEVES = "Deactivate_SUBSCRIPTIONS_RECIEVES";
export const Deactivate_SUBSCRIPTIONS_END = "Deactivate_SUBSCRIPTIONS_END";