import { useEffect, useState, SyntheticEvent } from "react";
import { useSelector } from "src/store/index";
import { useDispatch } from "src/store";
import { subscriptionList, createFreeSubscription, createMeteredSubscription, activateSubscription, updateFreeSubscription, updateMeteredSubscription } from "src/store/subscription/actions";
import { featureList, createFeature, updateFeature, deleteFeature } from "src/store/subscriptionFeature/action";
import { SubscriptionFeature } from "src/types/subscriptionFeature";

const useSubscription = () => {
	const dispatch = useDispatch();

	const [currentTab, setCurrentTab] = useState("Basic Information");
	const [subscriptionId, setSubscriptionId] = useState('');
	const [featureId, setFeatureId] = useState('');
	const [isUpdateFeature, setIsUpdateFeature] = useState(false);
	const [isUpdateSubscription, setIsUpdateSubscription] = useState(false);
	const [subscriptionPrice, setSubscriptionPrice] = useState(0);
	const [isMetered, setIsMetered] = useState(false);

	const [subscriptionData, setSubscriptionData] = useState({
		title: '',
		displayOnMain: true,
		description: '',
		isRecommended: false,
	});

	const [featureData, setfeatureData] = useState({
		planId: '',
		title: '',
		detail: '',
		featureType: '',
		featureValue: 0,
	});
	const [loadingStates, setLoadingStates] = useState({
		fetchAllPlans: false,
	  });
	
	  const handleLoadingStates = (key: string, value: boolean) => {
		setLoadingStates({
		  ...loadingStates,
		  [key]: value,
		});
	  };
	
	const { data: subscriptions = [] } = useSelector((state) => state?.Subscriptions?.SubscriptionsReducer);
	const { data: inProcessSubscription = {} } = useSelector((state) => state?.Subscriptions?.CreateSubscriptionsReducer);

	const { data: subscriptionFeatures = [] } = useSelector((state) => state?.Features?.SubscriptionFeature);

	const queryParams = new URLSearchParams(window.location.search);
	const subscriptionID = queryParams.get('id');

	useEffect(() => {
		dispatch(subscriptionList(handleLoadingStates));
    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let id = queryParams.get('id');
		if(id) {
			setSubscriptionId(id);
			setIsUpdateSubscription(true);
			dispatch(featureList(id.toString()));
			if(subscriptions?.length) {
				const subscription = subscriptions?.length && subscriptions?.filter((item) => item?.id === id.toString());
				setSubscriptionData((prevData) => ({
					...prevData,
					title: subscription[0]?.title,
					displayOnMain: subscription[0]?.displayOnMain,
					description: subscription[0]?.description,
				}));
				if(subscription && subscription[0]?.type === "METERED") {
					setSubscriptionPrice(subscription[0]?.price);
				}
			}
		}
    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subscriptions]);

	const handleSubscriptionSave = () => {
		if(isMetered) {
			const body = {
				...subscriptionData,
				price: Number(subscriptionPrice),
				refundAllowed: false
			}
			if(isUpdateSubscription) {
				dispatch(updateMeteredSubscription(body, subscriptionId ? subscriptionId : inProcessSubscription?.id));
			} else {
				dispatch(createMeteredSubscription(body, setIsUpdateSubscription));
			}
		} else {
			const body = {
				...subscriptionData,
				purchaseOnce: true,
			}
			if(isUpdateSubscription) {
				dispatch(updateFreeSubscription(body, subscriptionId ? subscriptionId : inProcessSubscription?.id));
			} else {
				dispatch(createFreeSubscription(body, setIsUpdateSubscription));
			}
		}
	}

	const handleFeatureSave = () => {
		const body: SubscriptionFeature = {
			...featureData,
			featureValue: Number(featureData?.featureValue),
			planId: subscriptionId ? subscriptionId : inProcessSubscription?.id,
		}
		if(isUpdateFeature) {			
			dispatch(updateFeature(body, featureId, setFeatureToDefault, setIsUpdateFeature));
		} else {
			dispatch(createFeature(body, setFeatureToDefault));
		}

	}

	const setFeatureToDefault = () => {
		setfeatureData({
			planId: '',
			title: '',
			detail: '',
			featureType: '',
			featureValue: 0,
		});
	}

	const handleTabChange = (event: SyntheticEvent, newTab: string) => {
		setCurrentTab(newTab);
	};

	const handleChangeSubscription = (key: string, event: any) => {
		setSubscriptionData((prevData) => ({
			...prevData,
			[key]: key === "displayOnMain" ? event.target.checked : event.target.value
		}));
	};

	const handleSubscriptionPrice = (event: any) => {
		setSubscriptionPrice(Number(event?.target?.value));
	}

	const handleChangeFeature = (key: string, event: any) => {
		setfeatureData((prevData) => ({
			...prevData,
			[key]: event.target.value
		}));
	};

	const handleEditFeature = (feature: SubscriptionFeature) => {
		setIsUpdateFeature(true);
		setfeatureData((prevData) => ({
			...prevData,
			planId: feature?.planId,
			detail: feature?.detail,
			featureType: feature?.featureType,
			featureValue: feature?.featureValue,
			title: feature?.title,
		}));
		setSubscriptionId(feature?.planId);
		setFeatureId(feature?.id);
	};

	const handleActivateSubscription = () => {
		dispatch(activateSubscription(subscriptionId ? subscriptionId : inProcessSubscription?.id, handleLoadingStates));
		setTimeout(() => {
			window.location.replace('/');
		}, 3000);
	};

	const handleDeleteFeature = (id: string) => {
		dispatch(deleteFeature(id, subscriptionId ? subscriptionId : inProcessSubscription?.id));
	}

	return {
		currentTab,
		featureData,
		setIsMetered,
		handleTabChange,
		isUpdateFeature,
		handleFeatureSave,
		handleEditFeature,
		subscriptionPrice,
		handleChangeFeature,
		handleDeleteFeature,
		subscriptionFeatures,
		subscriptionData,
		isUpdateSubscription,
		handleSubscriptionSave,
		handleSubscriptionPrice,
		handleChangeSubscription,
		handleActivateSubscription,
		inProcessSubscription,
		subscriptionID
	};
};

export default useSubscription;
