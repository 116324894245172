import { useEffect, useState } from "react";
import { useSelector } from "src/store/index";
import { useDispatch } from "src/store";
import { subscriptionList, activateSubscription, deActivateSubscription, deleteSubscription } from "src/store/subscription/actions";

const useSubscriptionManagement = () => {
	const dispatch = useDispatch();

	const { data: subscriptions = [] } = useSelector((state) => state?.Subscriptions?.SubscriptionsReducer);
	const [loadingStates, setLoadingStates] = useState({
		fetchAllPlans: false,
	  });
	
	  const handleLoadingStates = (key: string, value: boolean) => {
		setLoadingStates({
		  ...loadingStates,
		  [key]: value,
		});
	  };
	useEffect(() => {
		dispatch(subscriptionList(handleLoadingStates));
    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSubscriptionStatus = (status: string, id: string) => {
		if(status === "I") {
			dispatch(activateSubscription(id, handleLoadingStates));
		} else {
			dispatch(deActivateSubscription(id, handleLoadingStates));
		}
	};

	const handleDeleteSubscription = (id: string) => {
		dispatch(deleteSubscription(id, handleLoadingStates));
	}

	const isFreePlanActive = () => {
		const result = subscriptions?.find((subscription)=>!subscription?.isPaid && subscription?.status === 'A')
		return result
	}

	return {
		subscriptions,
		handleDeleteSubscription,
		handleSubscriptionStatus,
		isFreePlanActive,
		loadingStates
	};
};
export default useSubscriptionManagement;
