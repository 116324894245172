import {
	Container,
	Box,
	Tab,
	Tabs,
} from "@mui/material";

const TabsComponent = (props) => {
	const {currentTab, handleTabChange, disableTabs} = props;
	return (
		<>
			<Container>
				<Box>
					<Tabs value={currentTab} onChange={handleTabChange} sx={{border:"none", borderBottom:"1px solid rgba(255,255,255,0.2)"}}>
						<Tab label="Basic Information" value={"Basic Information"} sx={{ p: 2, mr: 2, ml: 1 }} />
						<Tab label="Manage Features" value={"Manage Features"} sx={{ p: 2, mx: 2 }} disabled={disableTabs}/>
						<Tab label="Setting" value={"Setting"} sx={{ p: 2, mx: 2 }} disabled={disableTabs} />
					</Tabs>
				</Box>
			</Container>
		</>
	);
};

export default TabsComponent;
