import { useState, type FC } from "react";
import {
  Box,
  Container,
  TextField,
  Autocomplete,
} from "@mui/material";
import DialogLayout from "../DialogLayout";


const CreateBotThemeModal: FC<any> = (props) => {
  const {
    isCreateBotThemeModal,
    handleCloseCreateModal,
    botThemeInput,
    handleCreateBotTheme,
    loadingStates,
    handleBotThemeInput,
    creditFeaturePolicies
  } = props;

  const [submitClicked, setSubmitClicked] = useState(false);

  const creditPolicyOptions = creditFeaturePolicies?.map((policy: any) => ({
    label: policy.title,
    id: policy.id,
  })) || [];

  return (
    <DialogLayout
      isOpen={isCreateBotThemeModal}
      onClose={handleCloseCreateModal}
      title="Create Bot Theme"
      action="Submit"
      handleAction={() => {
        setSubmitClicked(true)
        if (botThemeInput.title && botThemeInput.link) {
          handleCreateBotTheme()
        }
      }}
      isLoading={loadingStates.createBot}>
      <>
        <Container
          sx={{
            display: "flex",
            gap: 2,
            mt: 3,
            mb: 2,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <TextField
              label="Bot Theme Title"
              value={botThemeInput?.title}
              onChange={(e) => handleBotThemeInput('title', e.target.value)}
              sx={{
                width: "100%",
                mb: 3,
              }}
              error={
                submitClicked && !botThemeInput.title
              }
              helperText={
                submitClicked && !botThemeInput.title
                  ? "Bot Theme title should not be empty."
                  : ""
              }
            />
            <TextField
              label="Bot Theme Link"
              value={botThemeInput?.link}
              onChange={(e) => handleBotThemeInput('link', e.target.value)}
              sx={{
                width: "100%",
                mb: 3,
              }}
              error={
                submitClicked && !botThemeInput.link
              }
              helperText={
                submitClicked && !botThemeInput.link
                  ? "Bot theme link should not be empty."
                  : ""
              }
            />
            <Autocomplete
              options={creditPolicyOptions}
              getOptionLabel={(option: any) => option.label}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleBotThemeInput('devPolicyId', newValue.id);
                }
              }}
              clearIcon={null}
              noOptionsText="No Credit Feature Policies available"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Dev Bot Policy"
                  sx={{
                    width: "100%",
                    mb: 3,
                  }}
                  error={
                    submitClicked && !botThemeInput.devPolicyId
                  }
                  helperText={
                    submitClicked && !botThemeInput.devPolicyId
                      ? "You must select dev bot policy."
                      : ""
                  }
                />
              )}
            />

            <Autocomplete
              options={creditPolicyOptions}
              getOptionLabel={(option: any) => option.label}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleBotThemeInput('prodPolicyId', newValue.id);
                }
              }}
              clearIcon={null}
              noOptionsText="No Credit Feature Policies available"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Prod Bot Policy"
                  sx={{
                    width: "100%",
                    mb: 3,
                  }}
                  error={
                    submitClicked && !botThemeInput.prodPolicyId
                  }
                  helperText={
                    submitClicked && !botThemeInput.prodPolicyId
                      ? "You must select prod bot policy."
                      : ""
                  }
                />
              )}
            />

          </Box>
        </Container>
      </>
    </DialogLayout>
  );
};

export default CreateBotThemeModal;
