// import { useState } from 'react';
import type { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@mui/material/styles';
import DashboardSidebar from './DashboardSidebar';
// import DashboardSidebar from './DashboardSidebar';
// import { get } from 'lodash';
// import { useSelector } from 'src/store';

interface DashboardLayoutProps {
  children?: ReactNode;
}

const DashboardLayoutRoot = experimentalStyled('div')(
  ({ theme }) => (
    {
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      height: '100%',
      overflow: 'hidden',
      width: '100%'
    }
  )
);

// const DashboardLayoutWrapper = experimentalStyled('div')(
//   ({ theme }) => (
//     {
//       display: 'flex',
//       flex: '1 1 auto',
//       overflow: 'hidden',
//       paddingTop: '64px',
//       [theme.breakpoints.up('lg')]: {
//         paddingLeft: '280px'
//       }
//     }
//   )
// );

const DashboardLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const DashboardLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch'
});

// const coinProfileSelector = (state) => get( state, 'ui.CoinProfilerDialog.coin' );

const DashboardLayout: FC<DashboardLayoutProps> = () => {
  // const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState<boolean>(false);
  // const coinProfileState = useSelector( coinProfileSelector );

  return (
    <DashboardLayoutRoot>
     <DashboardSidebar
      />
      {/* <DashboardLayoutWrapper> */}
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      {/* </DashboardLayoutWrapper> */}
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
