import { Helmet } from "react-helmet-async";
import {
  Container,
  Grid,
  Typography,
  Breadcrumbs,
  Link,
  Box,
  Button,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import ChevronRightIcon from "src/icons/ChevronRight";
import useBotThemes from "src/hooks/useBotTheme";
import BotThemesTable from "src/components/dashboard/botThemes/BotThemesTable";
import CreateBotThemeModal from "src/components/dashboard/botThemes/CreateBotThemeModal";
import UpdateBotThemeModal from "src/components/dashboard/botThemes/UpdateBotThemeModal";

const BotThemePage = () => {

  const {
    botThemes,
    loadingStates,
    botThemeInput,
    handleUpdateModal,
    handleDeleteModal,
    updateBotThemeInput,
    handleBotThemeInput,
    handleUpdateBotTheme,
    handleCreateBotTheme,
    handleDeleteBotTheme,
    isCreateBotThemeModal,
    isDeleteBotThemeModal,
    isUpdateBotThemeModal,
    creditFeaturePolicies,
    handleCloseCreateModal,
    setIsCreateBotThemeModal,
    setIsDeleteBotThemeModal,
    setIsUpdateBotThemeModal,
    handleUpdateBotThemeInput, 
  } = useBotThemes()
  return (
    <>
      <Helmet>
        <title>APEXE3 Revolutions</title>
      </Helmet>
      <Container>
        <Grid position="relative" height="100vh" sx={{ py: 4, mt: 8 }}>
          <Grid item sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              <Typography color="textPrimary" variant="h5">
                Bot Theme Management
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/"
                  variant="subtitle2"
                >
                  Overview
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  Bot Themes
                </Typography>
              </Breadcrumbs>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "right",
                gap: 2,
                mt: 3,
              }}
            >
              <Button
                size="small"
                variant="outlined"
                sx={{ fontSize: "14px", borderRadius: "10px" }}
                onClick={()=>setIsCreateBotThemeModal(true)}
              >
                Create Bot Theme
              </Button>
              {/* <Button
                size="small"
                variant="outlined"
                sx={{ fontSize: "14px", borderRadius: "10px" }}
                href="/free-starter-plan"
              >
                Create Free Plan
              </Button> */}
            </Box>
          </Grid>
          <Grid item>
            <BotThemesTable
              botThemes={botThemes}
              loadingStates={loadingStates}
              isDeleteBotThemeModal={isDeleteBotThemeModal}
              setIsDeleteBotThemeModal={setIsDeleteBotThemeModal}
              handleDeleteBotTheme={handleDeleteBotTheme}
              handleDeleteModal={handleDeleteModal}
              handleUpdateModal={handleUpdateModal}
            />
          </Grid>
        </Grid>
        {
          isCreateBotThemeModal &&
          <CreateBotThemeModal
          isCreateBotThemeModal={isCreateBotThemeModal} 
          handleCloseCreateModal={handleCloseCreateModal} 
          botThemeInput={botThemeInput}
          handleCreateBotTheme={handleCreateBotTheme} 
          loadingStates={loadingStates}
          handleBotThemeInput={handleBotThemeInput} 
          creditFeaturePolicies={creditFeaturePolicies}
          />
        }

        {
          isUpdateBotThemeModal &&
          <UpdateBotThemeModal
            isUpdateBotThemeModal={isUpdateBotThemeModal}
            setIsUpdateBotThemeModal={setIsUpdateBotThemeModal}
            updateBotThemeInput={updateBotThemeInput}
            handleUpdateBotTheme={handleUpdateBotTheme}
            loadingStates={loadingStates}
            handleUpdateBotThemeInput={handleUpdateBotThemeInput}
            creditFeaturePolicies={creditFeaturePolicies}

          />
        }
      </Container>
    </>
  );
};

export default BotThemePage;
