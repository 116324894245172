import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
	Container,
	Typography,
	Grid,
	Box,
	Button
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import useSubscription from "src/hooks/useSubscription";

import SettingTab from "src/components/dashboard/subscriptionPlans/SettingTab";
import TabsComponent from "src/components/dashboard/subscriptionPlans/TabsComponent";
import ManageFeaturesTab from "src/components/dashboard/subscriptionPlans/ManageFeatureTab";
import BasicInformationTab from "src/components/dashboard/subscriptionPlans/BasicInformationTab";

const MeteredSubscriptionPage = () => {

	const {
		currentTab,
		featureData,
		setIsMetered,
		handleTabChange,
		isUpdateFeature,
		subscriptionData,
		handleFeatureSave,
		handleEditFeature,
		subscriptionPrice,
		handleChangeFeature,
		handleDeleteFeature,
		isUpdateSubscription,
		subscriptionFeatures,
		handleSubscriptionSave,
		handleSubscriptionPrice,
		handleChangeSubscription,
		handleActivateSubscription,
		inProcessSubscription,
		subscriptionID
	} = useSubscription();

	const [disableTabs, setDisableTabs] = useState(false);

    useEffect(() => {
        // Assuming inProcessSubscription is your state
        if (inProcessSubscription && inProcessSubscription.length !== 0) {
            setDisableTabs(false);
        } else {
            setDisableTabs(true);
        }
    }, [inProcessSubscription]);

	useEffect(() => {
		setIsMetered(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Helmet>
				<title>APEXE3 Revolutions</title>
			</Helmet>
			<Container>
				<Grid position="relative" height="100vh" sx={{ py: 4, mt: 10 }}>
				<Button sx={{ mb: 2 }} onClick={() => window.location.replace('/')}>
						<ArrowBackIcon />
					</Button>
					<Grid item>
						<Typography variant="h4" component="h2" mb={3} ml={3}>
							Metered Subscription Plan
						</Typography>
						<Box>
							<TabsComponent
								currentTab={currentTab}
								handleTabChange={handleTabChange}
								disableTabs={!subscriptionID ? disableTabs : false}
							/>
							<Box sx={{ padding: 2 }}>
								{currentTab === "Basic Information" && (
									<BasicInformationTab
										metered={true}
										subscriptionData={subscriptionData}
										subscriptionPrice={subscriptionPrice}
										handleChange={handleChangeSubscription}
										isUpdateSubscription={isUpdateSubscription}
										handleSubscriptionSave={handleSubscriptionSave}
										handleSubscriptionPrice={handleSubscriptionPrice}
									/>
								)}
								{currentTab === "Manage Features"  && (
									<ManageFeaturesTab
										featureData={featureData}
										isUpdateFeature={isUpdateFeature}
										handleChangeFeature={handleChangeFeature}
										handleEditFeature={handleEditFeature}
										handleFeatureSave={handleFeatureSave}
										handleDeleteFeature={handleDeleteFeature}
										subscriptionFeatures={subscriptionFeatures}
									/>
								)}
								{currentTab === "Setting" && (
									<SettingTab
										SubscriptionDes={subscriptionData?.description}
										handleActivateSubscription={handleActivateSubscription}
									/>
								)}
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Container>
		</>
	);
};

export default MeteredSubscriptionPage;
