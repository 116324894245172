export const authenticationConfig = () =>  {
  return{  
    baseUrl: (window as any).REACT_APP_AUTHENTICATION_BASE_URL || process.env.REACT_APP_AUTHENTICATION_BASE_URL,
  }
};

export const subscriptionConfig = () => {
  return{
    baseUrl: (window as any).REACT_APP_SUBSCRIPTION_ENGINE_BASE_URL || process.env.REACT_APP_SUBSCRIPTION_ENGINE_BASE_URL,
  }
};

export const botEngineConfig = () => {
  return{
    baseUrl: (window as any).REACT_APP_BOT_ENGINE_BASE_URL || process.env.REACT_APP_BOT_ENGINE_BASE_URL,
  }
}
