export const FETCH_CREDIT_FEATURE_POLICIES_START = "FETCH_CREDIT_FEATURE_POLICIES_START";
export const FETCH_CREDIT_FEATURE_POLICIES_RECIEVES = "FETCH_CREDIT_FEATURE_POLICIES_RECIEVES";
export const FETCH_CREDIT_FEATURE_POLICIES_END = "FETCH_CREDIT_FEATURE_POLICIES_END";

export const FETCH_SINGLE_CREDIT_FEATURE_POLICY_START = "FETCH_SINGLE_CREDIT_FEATURE_POLICY_START";
export const FETCH_SINGLE_CREDIT_FEATURE_POLICY_RECIEVES = "FETCH_SINGLE_CREDIT_FEATURE_POLICY_RECIEVES";
export const FETCH_SINGLE_CREDIT_FEATURE_POLICY_END = "FETCH_SINGLE_CREDIT_FEATURE_POLICY_END";

export const CREATE_CREDIT_FEATURE_POLICY_START = "CREATE_CREDIT_FEATURE_POLICY_START";
export const CREATE_CREDIT_FEATURE_POLICY_RECIEVES = "CREATE_CREDIT_FEATURE_POLICY_RECIEVES";
export const CREATE_CREDIT_FEATURE_POLICY_END = "CREATE_CREDIT_FEATURE_POLICY_END";

export const UPDATE_CREDIT_FEATURE_POLICY_START = "UPDATE_CREDIT_FEATURE_POLICY_START";
export const UPDATE_CREDIT_FEATURE_POLICY_RECIEVES = "UPDATE_CREDIT_FEATURE_POLICY_RECIEVES";
export const UPDATE_CREDIT_FEATURE_POLICY_END = "UPDATE_CREDIT_FEATURE_POLICY_END";

export const DELETE_CREDIT_FEATURE_POLICY_START = "DELETE_CREDIT_FEATURE_POLICY_START";
export const DELETE_CREDIT_FEATURE_POLICY_RECIEVES = "DELETE_CREDIT_FEATURE_POLICY_RECIEVES";
export const DELETE_CREDIT_FEATURE_POLICY_END = "DELETE_CREDIT_FEATURE_POLICY_END";

export const ACTIVATE_CREDIT_FEATURE_POLICY_START = "ACTIVATE_CREDIT_FEATURE_POLICY_START";
export const ACTIVATE_CREDIT_FEATURE_POLICY_RECIEVES = "ACTIVATE_CREDIT_FEATURE_POLICY_RECIEVES";
export const ACTIVATE_CREDIT_FEATURE_POLICY_END = "ACTIVATE_CREDIT_FEATURE_POLICY_END";

export const DEACTIVATE_CREDIT_FEATURE_POLICY_START = "DEACTIVATE_CREDIT_FEATURE_POLICY_START";
export const DEACTIVATE_CREDIT_FEATURE_POLICY_RECIEVES = "DEACTIVATE_CREDIT_FEATURE_POLICY_RECIEVES";
export const DEACTIVATE_CREDIT_FEATURE_POLICY_END = "DEACTIVATE_CREDIT_FEATURE_POLICY_END";

export const SET_CREDIT_FEATURE_POLICY_DEFAULT_START = "SET_CREDIT_FEATURE_POLICY_DEFAULT_START";
export const SET_CREDIT_FEATURE_POLICY_DEFAULT_RECIEVES = "SET_CREDIT_FEATURE_POLICY_DEFAULT_RECIEVES";
export const SET_CREDIT_FEATURE_POLICY_DEFAULT_END = "SET_CREDIT_FEATURE_POLICY_DEFAULT_END";