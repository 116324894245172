import { combineReducers } from "@reduxjs/toolkit";
import {
  FETCH_CREDIT_FEATURE_POLICIES_START ,
  FETCH_CREDIT_FEATURE_POLICIES_RECIEVES, 
  FETCH_CREDIT_FEATURE_POLICIES_END ,
  CREATE_CREDIT_FEATURE_POLICY_START ,
  CREATE_CREDIT_FEATURE_POLICY_RECIEVES, 
  CREATE_CREDIT_FEATURE_POLICY_END ,
  UPDATE_CREDIT_FEATURE_POLICY_START, 
  UPDATE_CREDIT_FEATURE_POLICY_RECIEVES, 
  UPDATE_CREDIT_FEATURE_POLICY_END ,
  DELETE_CREDIT_FEATURE_POLICY_START, 
  DELETE_CREDIT_FEATURE_POLICY_RECIEVES, 
  DELETE_CREDIT_FEATURE_POLICY_END ,
	FETCH_SINGLE_CREDIT_FEATURE_POLICY_END,
	FETCH_SINGLE_CREDIT_FEATURE_POLICY_RECIEVES,
	FETCH_SINGLE_CREDIT_FEATURE_POLICY_START
} from "./action-types";

export const CreditFeaturePolicies = (
	state: { loading: boolean; data: any } = {
		loading: false,
		data: { data: [] },
	},
	{ type, data, error }
) => {
	switch (type) {
		case FETCH_CREDIT_FEATURE_POLICIES_START: {
			return {
				loading: true,
				data: [],
				error: "",
			};
		}
		case FETCH_CREDIT_FEATURE_POLICIES_RECIEVES: {
			return {
				loading: false,
				data,
				error: "",
			};
		}
		case FETCH_CREDIT_FEATURE_POLICIES_END: {
			return {
				loading: false,
				data: [],
				error,
			};
		}
		default:
			return state;
	}
};

export const CreditFeaturePolicy = (
	state: { loading: boolean; data: any } = {
		loading: false,
		data: { data: [] },
	},
	{ type, data, error }
) => {
	switch (type) {
		case FETCH_SINGLE_CREDIT_FEATURE_POLICY_START: {
			return {
				loading: true,
				data: [],
				error: "",
			};
		}
		case FETCH_SINGLE_CREDIT_FEATURE_POLICY_RECIEVES: {
			return {
				loading: false,
				data,
				error: "",
			};
		}
		case FETCH_SINGLE_CREDIT_FEATURE_POLICY_END: {
			return {
				loading: false,
				data: [],
				error,
			};
		}
		default:
			return state;
	}
};

export const CreateCreditFeaturePolicy = (
	state: { loading: boolean; data: any } = {
		loading: false,
		data: { data: [] },
	},
	{ type, data, error }
) => {
	switch (type) {
		case CREATE_CREDIT_FEATURE_POLICY_START: {
			return {
				loading: true,
				data: [],
				error: "",
			};
		}
		case CREATE_CREDIT_FEATURE_POLICY_RECIEVES: {
			return {
				loading: false,
				data,
				error: "",
			};
		}
		case CREATE_CREDIT_FEATURE_POLICY_END: {
			return {
				loading: false,
				data: [],
				error,
			};
		}
		default:
			return state;
	}
};

export const UpdateCreditFeaturePolicy = (
	state: { loading: boolean; data: any } = {
		loading: false,
		data: { data: [] },
	},
	{ type, data, error }
) => {
	switch (type) {
		case UPDATE_CREDIT_FEATURE_POLICY_START: {
			return {
				loading: true,
				data: [],
				error: "",
			};
		}
		case UPDATE_CREDIT_FEATURE_POLICY_RECIEVES: {
			return {
				loading: false,
				data,
				error: "",
			};
		}
		case UPDATE_CREDIT_FEATURE_POLICY_END: {
			return {
				loading: false,
				data: [],
				error,
			};
		}
		default:
			return state;
	}
};
export const DeleteCreditFeaturePolicy = (
	state: { loading: boolean; data: any } = {
		loading: false,
		data: { data: [] },
	},
	{ type, data, error }
) => {
	switch (type) {
		case DELETE_CREDIT_FEATURE_POLICY_START: {
			return {
				loading: true,
				data: [],
				error: "",
			};
		}
		case DELETE_CREDIT_FEATURE_POLICY_RECIEVES: {
			return {
				loading: false,
				data,
				error: "",
			};
		}
		case DELETE_CREDIT_FEATURE_POLICY_END: {
			return {
				loading: false,
				data: [],
				error,
			};
		}
		default:
			return state;
	}
};

export default combineReducers({
	CreditFeaturePolicies,
	CreateCreditFeaturePolicy,
  UpdateCreditFeaturePolicy,
  DeleteCreditFeaturePolicy,
	CreditFeaturePolicy
});