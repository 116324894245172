import { Box, Link, TableCell, Switch } from "@mui/material";
import toast from "react-hot-toast";
import { Link as RouterLink } from "react-router-dom";

import { alpha, styled } from "@mui/material/styles";

import IconButton from "@mui/material/IconButton";
import Trash from "src/icons/Trash";
import PencilAlt from "src/icons/PencilAlt";

// import editIcon from "../../../assets/edit.svg"
// import delIcon from "../../../assets/delete.svg"

const ActionColumn = (props) => {
  const { subscription, handleSubscriptionStatus, handleDeleteSubscription, isFreePlanActive } =
    props;

  const handleEdit = (status: string) => {
    if (status === "A") {
      toast.error("Inactive your subscription first", {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        },
        });
    }
  };

  const GreenSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#76d14d",
      "&:hover": {
        backgroundColor: alpha("#76d14d", theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#76d14d",
    },
  }));

  return (
    <TableCell>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        //   justifyContent: "space-evenly",
        }}
      >
        <Link
          component={RouterLink}
          to={
            subscription?.status === "I"
              ? subscription?.type === "FREE"
                ? `/free-starter-plan?id=${subscription?.id}`
                : `/metered-subscription-plan?id=${subscription?.id}`
              : "/"
          }
          onClick={() => handleEdit(subscription?.status)}
          variant="subtitle2"
          sx={{ fontSize: "14px" }}
        >
          <IconButton>
            <PencilAlt  />
          </IconButton>
        </Link>
        <Box
          sx={{ fontSize: "14px", cursor: "pointer" }}
          onClick={() => handleDeleteSubscription(subscription?.id)}
        >
          <IconButton>
            <Trash color="error" />
          </IconButton>
        </Box>
        <Box
          sx={{ fontSize: "14px", cursor: "pointer" }}
          onClick={() => {
            const freeActivePlan = isFreePlanActive()

            if(freeActivePlan && !subscription?.isPaid && freeActivePlan?.id !== subscription?.id)
            {
              toast.error("Deactivate other free plan first.", {
                        style: {
                          borderRadius: '10px',
                          background: '#333',
                          color: '#fff',
                        },
                      });
                      return
            }
        
            if (!subscription?.features) {
              toast.error("Add Features in this subscription first.", {
                style: {
                  borderRadius: '10px',
                  background: '#333',
                  color: '#fff',
                },
              });
              return; // Return to prevent further execution
            }
        
            handleSubscriptionStatus(subscription?.status, subscription?.id);
          }}
        >
          {subscription?.status === "I" ? (
            <GreenSwitch disabled={!subscription?.features || (isFreePlanActive() && !subscription?.isPaid && isFreePlanActive()?.id !== subscription?.id)} />
          ) : (
            <GreenSwitch defaultChecked />
          )}
        </Box>
      </Box>
    </TableCell>
  );
};

export default ActionColumn;
