export const CREATE_POLICY_FEATURE_START = "CREATE_POLICY_FEATURE_START";
export const CREATE_POLICY_FEATURE_RECIEVES = "CREATE_POLICY_FEATURE_RECIEVES";
export const CREATE_POLICY_FEATURE_END = "CREATE_POLICY_FEATURE_END";

export const UPDATE_POLICY_FEATURE_START = "UPDATE_POLICY_FEATURE_START";
export const UPDATE_POLICY_FEATURE_RECIEVES = "UPDATE_POLICY_FEATURE_RECIEVES";
export const UPDATE_POLICY_FEATURE_END = "UPDATE_POLICY_FEATURE_END";

export const DELETE_POLICY_FEATURE_START = "DELETE_POLICY_FEATURE_START";
export const DELETE_POLICY_FEATURE_RECIEVES = "DELETE_POLICY_FEATURE_RECIEVES";
export const DELETE_POLICY_FEATURE_END = "DELETE_POLICY_FEATURE_END";

export const FETCH_POLICY_FEATURES_START = "FETCH_POLICY_FEATURES_START";
export const FETCH_POLICY_FEATURES_RECIEVES = "FETCH_POLICY_FEATURES_RECIEVES";
export const FETCH_POLICY_FEATURES_END = "FETCH_POLICY_FEATURES_END";




