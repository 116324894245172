import * as React from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";


import { Link as RouterLink, useLocation } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import { Tooltip } from "@mui/material";
import DashboardNavbar from "./DashboardNavbar";

import CardMembershipIcon from '@mui/icons-material/CardMembership';
import AddCardIcon from '@mui/icons-material/AddCard';
import SmartToyIcon from '@mui/icons-material/SmartToy';

const sections = [
  {
    title: "General",
    items: [
      {
        title: "Subscriptions",
        path: "/",
        icon: <CardMembershipIcon fontSize="small" />,
      },
      {
        title: "Credit Feature Policies",
        path: "/credit-feature-policy",
        icon: <AddCardIcon fontSize="small" />,
      },
      {
        title: "Bot Themes",
        path: "/bot-themes",
        icon: <SmartToyIcon fontSize="small" />,
      },
     
    ],
  },
];

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: "#19191E",
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: "#19191E",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DashboardSidebar = () => {
  const { refreshToken, logoutSSO } = useAuth();
  const location = useLocation();

  React.useEffect(() => {
    const intervalDuration = 28 * 60 * 1000 + 30 * 1000;

    const checkTokenExpiration = () => {
      const sso = localStorage.getItem("sso");
      if (JSON.parse(sso)) {
        const currentTime = new Date().getTime();
        const refresh_token = JSON.parse(
          window.localStorage.getItem("refreshToken")
        );
        const timeExceeds = currentTime * 0.001 > refresh_token?.expiresIn;
        if (timeExceeds) {
          logoutSSO();
        }
      } else {
        const currentTime = new Date().getTime();
        const refresh_token = JSON.parse(
          window.localStorage.getItem("refreshToken")
        );
        const inTime = currentTime * 0.001 + 15 < refresh_token?.expiresIn;
        const timeExceeds = currentTime * 0.001 > refresh_token?.expiresIn;
        if (inTime) {
          refreshToken();
        } else if (timeExceeds) {
          logoutSSO();
          // logout();
        }
      }
      setTimeout(() => checkTokenExpiration(), intervalDuration);
    };
    checkTokenExpiration();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [open, setOpen] = React.useState(true);

  const handleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      
      
      <DashboardNavbar open={open} handleDrawer={handleDrawer}/>

      <Drawer variant="permanent" open={open}>
        <Box
          sx={{
            my: "15px",
            marginLeft: "18px",
            width: `${open ? "100%" : "25px"}`,
            overflow: "hidden",
          }}
        >
          <img
            alt="Apex:E3 Logo"
            height="28"
            src="/static/Apex_Logo_2022Medium.png"
          />
        </Box>

        <Divider style={{marginBottom: '20px'}} />
        <List>
          {sections[0]?.items?.map((item, index) => (
            <RouterLink
              to={item?.path}
              style={{ textDecoration: "none", color: "white" }}
            >
              <ListItem
                key={index}
                disablePadding
                sx={{
                  display: "block",
                  backgroundColor:
                    location.pathname === item?.path
                      ? "#38383E"
                      : "transparent",
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <Tooltip title={item?.title}>
                    <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {item?.icon}
                  </ListItemIcon></Tooltip>
                  
                  <ListItemText
                    primary={item?.title}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </RouterLink>
          ))}
        </List>
      </Drawer>
    </Box>
  );
};

export default DashboardSidebar;