import axios from "axios";
import { getAccessToken } from "src/utils/token";
import toast from "react-hot-toast";
import { subscriptionConfig } from "src/config";
import { CreateFreeSubscription, CreateMeteredSubscription, CreateAutoRenewWithOutFree, CreateAutoRenewWithFree, UpdateSubscription } from "src/types/subscription";


class SubscriptionServiceApi {
	async fetchAllSubscriptions() {
		try {
			const { baseUrl } = subscriptionConfig();
			const token = getAccessToken("accessToken");
			const response = await axios.get(`${baseUrl}/subscriptions`, {
				headers: {
					Authorization: `Bearer ${token}`,
					"content-type": "application/json",
				},
			});

			if (response != null && response.data) {
				return response.data;
			} else {
				toast.success("No data recieved", {
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
				throw new Error("No data recieved");
			}
		} catch (e) {
			toast.error(e?.response?.data?.message || "Something went wrong", {
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
			return e?.response?.data?.message || "Something went wrong";
		}

	};

	async fetchAllActiveSubscriptions() {
		try {
			const { baseUrl } = subscriptionConfig();
			const token = getAccessToken("accessToken");
			const response = await axios.get(`${baseUrl}/subscriptions/active`, {
				headers: {
					Authorization: `Bearer ${token}`,
					"content-type": "application/json",
				},
			});

			if (response != null && response.data) {
				return response.data;
			} else {
				toast.success("No data recieved", {
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
				throw new Error("No data recieved");
			}
		} catch (e) {
			toast.error(e?.response?.data?.message || "Something went wrong", {
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
			return e?.response?.data?.message || "Something went wrong";
		}

	};

	async createOneTimeFreeSubscription(body: CreateFreeSubscription) {
		try {
			const { baseUrl } = subscriptionConfig();
			const token = getAccessToken("accessToken");

			const response = await axios.post(`${baseUrl}/subscriptions/one-time/free`, body, {
				headers: {
					Authorization: `Bearer ${token}`,
					"content-type": "application/json",
				},
			});

			if (response != null && response.data) {
				toast.success("Subscription Created Successfully", {
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
				return response?.data;
			} else {
				toast.success("No data recieved", {
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
				throw new Error("No data recieved");
			}
		} catch (e) {
			toast.error(e?.response?.data?.message || "Something went wrong", {
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
			return e?.response?.data?.message || "Something went wrong";
		}

	};

	async createMeteredSubscription(body: CreateMeteredSubscription) {
		try {
			const { baseUrl } = subscriptionConfig();
			const token = getAccessToken("accessToken");

			const response = await axios.post(`${baseUrl}/subscriptions/metered`, body, {
				headers: {
					Authorization: `Bearer ${token}`,
					"content-type": "application/json",
				},
			});

			if (response != null && response.data) {
				toast.success("Subscription Created Successfully", {
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
				return response?.data;
			} else {
				toast.success("No data recieved", {
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
				throw new Error("No data recieved");
			}
		} catch (e) {
			toast.error(e?.response?.data?.message || "Something went wrong", {
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
			return e?.response?.data?.message || "Something went wrong";
		}

	};

	async createAutoRenewWithOutFree(body: CreateAutoRenewWithOutFree) {
		try {
			const { baseUrl } = subscriptionConfig();
			const token = getAccessToken("accessToken");

			const response = await axios.post(`${baseUrl}/subscriptions`, body, {
				headers: {
					Authorization: `Bearer ${token}`,
					"content-type": "application/json",
				},
			});

			if (response != null && response.data) {
				toast.success("Subscription Created Successfully", {
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
				return response?.data;
			} else {
				toast.success("No data recieved", {
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
				throw new Error("No data recieved");
			}
		} catch (e) {
			toast.error(e?.response?.data?.message || "Something went wrong", {
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
			return e?.response?.data?.message || "Something went wrong";
		}

	};

	async createAutoRenewWithFree(body: CreateAutoRenewWithFree) {
		try {
			const { baseUrl } = subscriptionConfig();
			const token = getAccessToken("accessToken");

			const response = await axios.post(`${baseUrl}/subscriptions`, body, {
				headers: {
					Authorization: `Bearer ${token}`,
					"content-type": "application/json",
				},
			});

			if (response != null && response.data) {
				toast.success("Subscription Created Successfully", {
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
				return response?.data;
			} else {
				toast.success("No data recieved", {
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
				throw new Error("No data recieved");
			}
		} catch (e) {
			toast.error(e?.response?.data?.message || "Something went wrong", {
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
			return e?.response?.data?.message || "Something went wrong";
		}

	};

	async updateOneTimeFreeSubscription(body: UpdateSubscription, id: string) {
		try {
			const { baseUrl } = subscriptionConfig();
			const token = getAccessToken("accessToken");

			const response = await axios.patch(`${baseUrl}/subscriptions/one-time/free/${id}`, body, {
				headers: {
					Authorization: `Bearer ${token}`,
					"content-type": "application/json",
				},
			});

			if (response != null && response.data) {
				toast.success("Subscription Updated Successfully", {
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
				return response?.data;
			} else {
				toast.success("No data recieved", {
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
				throw new Error("No data recieved");
			}
		} catch (e) {
			toast.error(e?.response?.data?.message || "Something went wrong", {
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
			return e?.response?.data?.message || "Something went wrong";
		}

	};

	async updateMeteredSubscription(body: UpdateSubscription, id: string) {
		try {
			const { baseUrl } = subscriptionConfig();
			const token = getAccessToken("accessToken");

			const response = await axios.patch(`${baseUrl}/subscriptions/metered/${id}`, body, {
				headers: {
					Authorization: `Bearer ${token}`,
					"content-type": "application/json",
				},
			});

			if (response != null && response.data) {
				toast.success("Subscription Updated Successfully", {
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
				return response?.data;
			} else {
				toast.success("No data recieved", {
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
				throw new Error("No data recieved");
			}
		} catch (e) {
			toast.error(e?.response?.data?.message || "Something went wrong", {
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
			return e?.response?.data?.message || "Something went wrong";
		}

	};

	async activateSubscription(id: string) {
		try {
			const { baseUrl } = subscriptionConfig();
			const token = getAccessToken("accessToken");

			const response = await axios.post(`${baseUrl}/subscriptions/${id}/activate`, {
				headers: {
					Authorization: `Bearer ${token}`,
					"content-type": "application/json",
				},
			});

			if (response != null && response.data) {
				toast.success("Subscription Activated Successfully", {
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
				return response?.data;
			} else {
				toast.success("No data recieved", {
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
				throw new Error("No data recieved");
			}
		} catch (e) {
			toast.error(e?.response?.data?.message || "Something went wrong", {
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
			return e?.response?.data?.message || "Something went wrong";
		}

	};

	async deActivateSubscription(id: string) {
		try {
			const { baseUrl } = subscriptionConfig();
			const token = getAccessToken("accessToken");

			const response = await axios.post(`${baseUrl}/subscriptions/${id}/deactivate`, {
				headers: {
					Authorization: `Bearer ${token}`,
					"content-type": "application/json",
				},
			});

			if (response != null && response.data) {
				toast.success("Subscription DeActivated Successfully", {
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
				return response?.data;
			} else {
				toast.success("No data recieved", {
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
				throw new Error("No data recieved");
			}
		} catch (e) {
			toast.error(e?.response?.data?.message || "Something went wrong", {
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
			return e?.response?.data?.message || "Something went wrong";
		}

	};

	async deleteSubscription(id: string) {
		try {
			const { baseUrl } = subscriptionConfig();
			const token = getAccessToken("accessToken");

			const response = await axios.delete(`${baseUrl}/subscriptions/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`,
					"content-type": "application/json",
				},
			});

			if (response != null && response.data) {
				toast.success("Subscription deleted", {
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
				return response?.data;
			} else {
				toast.success("No data recieved", {
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
				throw new Error("No data recieved");
			}
		} catch (e) {
			toast.error(e?.response?.data?.message || "Something went wrong", {
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
			return e?.response?.data?.message || "Something went wrong";
		}

	};
};

export const subscriptionServiceApi = new SubscriptionServiceApi();
