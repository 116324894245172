import {
	Container,
	Grid,
	Box,
	Typography,
	Button,
} from "@mui/material";

const SettingTab = (props) => {

	const {
		SubscriptionDes,
		handleActivateSubscription
	} = props;

	return (
		<>
			<Container>
				<Box>
					<Grid xs={8} item textAlign={"center"} margin={"auto"}>
						<Box mt={12}>
							<Typography variant="body1" component="h2">
								You have not activated this subscription yet!
							</Typography>
							<Typography variant="h6" component="h2" sx={{ mt: 3 }}>
								{SubscriptionDes}
							</Typography>
						</Box>
						<Box sx={{ mt: 10, display: "flex", justifyContent: "space-between" }}>
							<Box></Box>
							<Box>
								<Button onClick={handleActivateSubscription} variant="contained" size="large" sx={{borderRadius:"10px"}}>
									Activate
								</Button>
							</Box>
						</Box>
					</Grid>
				</Box>
			</Container>
		</>
	);
};

export default SettingTab;
