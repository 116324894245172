import axios from "axios";
import { getAccessToken } from "src/utils/token";
import toast from "react-hot-toast";
import { subscriptionConfig } from "src/config";
import { SubscriptionFeature } from "src/types/subscriptionFeature";

class SubscriptionFeatureServiceApi {
	async createSubscriptionFeature(body: SubscriptionFeature) {
		try {
			const { baseUrl } = subscriptionConfig();
			const token = getAccessToken("accessToken");
			const response = await axios.post(`${baseUrl}/subscription-features`, body, {
				headers: {
					Authorization: `Bearer ${token}`,
					"content-type": "application/json",
				},
			});

			if (response != null && response.data) {
				toast.success("Feature Created Successfully",{
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  } );
				return response.data;
			} else {
				toast.success("No data recieved",{
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
				throw new Error("No data recieved");
			}
		} catch (e) {
			toast.error(e?.response?.data?.message || "Something went wrong",{
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
			return e?.response?.data?.message || "Something went wrong";
		}

	};

	async updateSubscriptionFeature(body: SubscriptionFeature, id: string) {
		try {			
			const { baseUrl } = subscriptionConfig();
			const token = getAccessToken("accessToken");
			const response = await axios.patch(`${baseUrl}/subscription-features/${id}`, body, {
				headers: {
					Authorization: `Bearer ${token}`,
					"content-type": "application/json",
				},
			});

			if (response != null && response.data) {
				toast.success("Feature Updated Successfully",{
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
				return response.data;
			} else {
				toast.success("No data recieved",{
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
				throw new Error("No data recieved");
			}
		} catch (e) {
			toast.error(e?.response?.data?.message || "Something went wrong",{
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
			return e?.response?.data?.message || "Something went wrong";
		}

	};

	async findSubscriptionFeature(id: string) {
		
		try {
			const { baseUrl } = subscriptionConfig();
			const token = getAccessToken("accessToken");
			const response = await axios.get(`${baseUrl}/subscription-features/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`,
					"content-type": "application/json",
				},
			});

			if (response != null && response.data) {
				return response.data;
			} else {
				toast.success("No data recieved",{
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
				throw new Error("No data recieved");
			}
		} catch (e) {
			toast.error(e?.response?.data?.message || "Something went wrong",{
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
			return e?.response?.data?.message || "Something went wrong";
		}

	};

	async deleteSubscriptionFeature(id: string) {
		try {
			const { baseUrl } = subscriptionConfig();
			const token = getAccessToken("accessToken");
			const response = await axios.delete(`${baseUrl}/subscription-features/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`,
					"content-type": "application/json",
				},
			});

			if (response != null && response.data) {
				toast.success("Feature Deleted Successfully",{
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
				return response.data;
			} else {
				toast.success("No data recieved",{
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
				throw new Error("No data recieved");
			}
		} catch (e) {
			toast.error(e?.response?.data?.message || "Something went wrong",{
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				  });
			return e?.response?.data?.message || "Something went wrong";
		}

	};

};

export const subscriptionFeatureServiceApi = new SubscriptionFeatureServiceApi();
