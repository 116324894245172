import { useState, type FC } from "react";
import {
  Box,
  Container,
  TextField,
  Autocomplete,
} from "@mui/material";
import DialogLayout from "../DialogLayout";



const UpdateBotThemeModal: FC<any> = (props) => {
  const {
    isUpdateBotThemeModal,
    setIsUpdateBotThemeModal,
    updateBotThemeInput,
    handleUpdateBotTheme,
    loadingStates,
    handleUpdateBotThemeInput,
    creditFeaturePolicies
    
  } = props;

  const [submitClicked, setSubmitClicked] = useState(false);

  const creditPolicyOptions = creditFeaturePolicies?.map((policy: any) => ({
    label: policy.title,
    id: policy.id,
  })) || [];

  return (
    <DialogLayout
      isOpen={isUpdateBotThemeModal}
      onClose={() => setIsUpdateBotThemeModal(false)}
      title="Update Bot Theme"
      action="Update"
      handleAction={() => {
        setSubmitClicked(true)
        if (updateBotThemeInput.title && updateBotThemeInput.link ) {
          handleUpdateBotTheme()
        }
      }}
      isLoading={loadingStates.updateBotTheme}>
      <>
        <Container
          sx={{
            display: "flex",
            gap: 2,
            mt: 3,
            mb: 2,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <TextField
              label="Bot Theme Title"
              value={updateBotThemeInput?.title}
              onChange={(e) => handleUpdateBotThemeInput('title', e.target.value)}
              sx={{
                width: "100%",
                mb: 3,
              }}
              error={
                submitClicked && !updateBotThemeInput.title
              }
              helperText={
                submitClicked && !updateBotThemeInput.title
                  ? "Bot Theme title should not be empty."
                  : ""
              }
            />
             <TextField
              label="Bot Theme Link"
              value={updateBotThemeInput?.link}
              onChange={(e) => handleUpdateBotThemeInput('link', e.target.value)}
              sx={{
                width: "100%",
                mb: 3,
              }}
              error={
                submitClicked && !updateBotThemeInput.link
              }
              helperText={
                submitClicked && !updateBotThemeInput.link
                  ? "Bot Theme link should not be empty."
                  : ""
              }
            />
            <Autocomplete
  options={creditPolicyOptions}
  getOptionLabel={(option: any) => option.label}
  value={creditPolicyOptions.find(option => option.id === updateBotThemeInput.devPolicyId) || null}
  onChange={(event, newValue) => {
    if (newValue) {
      handleUpdateBotThemeInput('devPolicyId', newValue.id);
    }
  }}
  clearIcon={null}
  noOptionsText="No Credit Feature Policies available"
  renderInput={(params) => (
    <TextField
      {...params}
      label="Select Dev Bot Policy"
      sx={{
        width: "100%",
        mb: 3,
      }}
      error={
        submitClicked && !updateBotThemeInput.devPolicyId
      }
      helperText={
        submitClicked && !updateBotThemeInput.devPolicyId
          ? "You must select dev bot policy."
          : ""
      }
    />
  )}
/>


            <Autocomplete
              options={creditPolicyOptions}
              getOptionLabel={(option: any) => option.label}
              value={creditPolicyOptions.find(option => option.id === updateBotThemeInput.prodPolicyId) || null}

              onChange={(event, newValue) => {
                if (newValue) {
                  handleUpdateBotThemeInput('prodPolicyId', newValue.id);
                }
              }}
              clearIcon={null}
              noOptionsText="No Credit Feature Policies available"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Prod Bot Policy"
                  sx={{
                    width: "100%",
                    mb: 3,
                  }}
                  error={
                    submitClicked && !updateBotThemeInput.prodPolicyId
                  }
                  helperText={
                    submitClicked && !updateBotThemeInput.prodPolicyId
                      ? "You must select prod bot policy."
                      : ""
                  }
                />
              )}
            />
          </Box>

        </Container>
      </>
    </DialogLayout>
  );
};

export default UpdateBotThemeModal;
