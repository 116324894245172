import {
  Grid,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  CircularProgress,
} from "@mui/material";

import ActionColumn from "./ActionColumn";

import DeleteDialog from "../DeleteDialog";


const BotThemesTable = (props) => {
  const {
    botThemes,
    loadingStates,
    isDeleteBotThemeModal,
    setIsDeleteBotThemeModal,
    handleDeleteBotTheme,
    handleDeleteModal,
    handleUpdateModal
  } = props;

  return (
    <Container>
      <Grid position="relative" height="" sx={{ py: 4 }}>

        <Grid item >
          <Grid>
            <TableContainer sx={{ maxHeight: 460, border: "none", boxShadow: "none", backgroundColor: "transparent !important" }} component={Paper} >
              <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left" sx={{ color: "#919eab" }}>Title</TableCell>
                    <TableCell align="left" sx={{ color: "#919eab" }}>Link</TableCell>
                    <TableCell align="center" sx={{ color: "#919eab" }}>Action</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    !loadingStates?.fetchAllBotThemes ?
                  botThemes?.length ? (
                    botThemes?.map((botTheme) => {
                      return (
                        <TableRow
                          key={botTheme?.id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            {botTheme?.title}
                          </TableCell>

                          <TableCell component="th" scope="row">
                            {botTheme?.link}
                          </TableCell>

                          <ActionColumn
                          botTheme={botTheme}
                          handleDeleteModal={handleDeleteModal}
                          handleUpdateModal={handleUpdateModal}

                          />
                        </TableRow>
                      );
                    })
                  ) : <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row" colSpan={5} align="center">
                    No Bot Themes
                  </TableCell>
                  
                </TableRow> :
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row" colSpan={6} align="center">
                    <CircularProgress/>
                  </TableCell>
                  
                </TableRow>
                
                }
                </TableBody>
              </Table>
            </TableContainer>
            {isDeleteBotThemeModal ? (
              <DeleteDialog
                isLoading={loadingStates.deleteBotTheme}
                isOpen={isDeleteBotThemeModal}
                setIsDeleteModal={setIsDeleteBotThemeModal}
                handleDelete={handleDeleteBotTheme}
                title={"Delete Bot Theme"}
              />
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default BotThemesTable;
