import { combineReducers } from "@reduxjs/toolkit";
import {
	FETCH_SUBSCRIPTIONS_LIST_START,
	FETCH_SUBSCRIPTIONS_LIST_RECIEVES,
	FETCH_SUBSCRIPTIONS_LIST_END,
	CREATE_SUBSCRIPTIONS_START,
	CREATE_SUBSCRIPTIONS_RECIEVES,
	CREATE_SUBSCRIPTIONS_END,
} from "./action-types";

export const SubscriptionsReducer = (
	state: { loading: boolean; data: any } = {
		loading: false,
		data: { data: [] },
	},
	{ type, data, error }
) => {
	switch (type) {
		case FETCH_SUBSCRIPTIONS_LIST_START: {
			return {
				loading: true,
				data: [],
				error: "",
			};
		}
		case FETCH_SUBSCRIPTIONS_LIST_RECIEVES: {
			return {
				loading: false,
				data,
				error: "",
			};
		}
		case FETCH_SUBSCRIPTIONS_LIST_END: {
			return {
				loading: false,
				data: [],
				error,
			};
		}
		default:
			return state;
	}
};

export const CreateSubscriptionsReducer = (
	state: { loading: boolean; data: any } = {
		loading: false,
		data: [],
	},
	{ type, data, error }
) => {
	switch (type) {
		case CREATE_SUBSCRIPTIONS_START: {
			return {
				loading: true,
				data: [],
				error: "",
			};
		}
		case CREATE_SUBSCRIPTIONS_RECIEVES: {
			return {
				loading: false,
				data,
				error: "",
			};
		}
		case CREATE_SUBSCRIPTIONS_END: {
			return {
				loading: false,
				data: [],
				error,
			};
		}
		default:
			return state;
	}
};

export default combineReducers({
	SubscriptionsReducer,
	CreateSubscriptionsReducer
});
