import { Helmet } from "react-helmet-async";
import {
	Container,
	Grid,
	Box,
	Typography,
	TextField,
	Button,
	InputLabel,
	MenuItem,
	FormControl,
	Select
} from "@mui/material";
import { useState } from "react";

const ManageFeaturesTab = (props) => {

	const {
		featureData,
		isUpdateFeature,
		handleEditFeature,
		subscriptionFeatures,
		handleChangeFeature,
		handleFeatureSave,
		handleDeleteFeature,
	} = props;

	const [submitClicked, setSubmitClicked] = useState(false);


	return (
		<>
			<Helmet>
				<title>APEXE3 Revolutions</title>
			</Helmet>
			<Container>
				<Box>
					<Grid container mt={3} spacing={5}>
						<Grid xs={6} lg={7} item>

						
							<TextField
							label="Title"
								type="text"
								variant="outlined"
								value={featureData?.title}
								onChange={(e) => handleChangeFeature('title', e)}
								sx={{ width: "100%", mb:3 }}
							/>
							
							<TextField
								id="description"
								label="Description"
								multiline
								rows={4}
								value={featureData?.detail}
								onChange={(e) => handleChangeFeature('detail', e)}
								variant="outlined"
								sx={{ width: "100%", mb:3 }}
							/>
							<Box sx={{ mt: "30px", display: "flex" }} gap={2}>
								<Box sx={{ width: "50%" }}>
									
									<Box sx={{ minWidth: 120 }}>
										<FormControl fullWidth>
											<InputLabel
												id="demo-simple-select-label">Feature</InputLabel>
											<Select
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												label="Type"
												value={featureData?.featureType}
												onChange={(e) => handleChangeFeature('featureType', e)}
											>
												<MenuItem value={'C'}>No. of Credits</MenuItem>
											</Select>
										</FormControl>
									</Box>
								</Box>
								<Box sx={{ width: "50%" }}>
								
									<TextField
									label="Value"
										type="number"
										variant="outlined"
										value={featureData?.featureValue}
										onChange={(e) => handleChangeFeature('featureValue', e)}
										style={{ width: "100%" }}
										error={
											submitClicked &&
											(!featureData?.featureValue || featureData?.featureValue < 1)
										}
										helperText={
											submitClicked &&
											featureData?.featureValue < 1
												? "Price must be greater than 0."
												: ""
										}
									/>
								</Box>
							</Box>
							<Box sx={{ mt: 7, display: "flex", justifyContent: "space-between" }}>
								<Box></Box>
								<Box>
									<Button onClick={()=>{
										setSubmitClicked(true);
										if(featureData?.featureValue > 0)
										{handleFeatureSave()
											setSubmitClicked(false);
										}
										}} variant="contained" size="large" sx={{borderRadius:"10px"}}>
										{isUpdateFeature ? "Update" : "Save"}
									</Button>
								</Box>
							</Box>
						</Grid>
						<Grid xs={6} lg={5} item>
							<Box sx={{ display: "flex" }}>
								<Box width={"1.5px"} height={"620px"} bgcolor={"white"} mr={2}></Box>
								<Box width={"100%"}>
									<Typography sx={{ fontSize: "18px", mt: "10px", mb: "10px" }}>
										Features List
									</Typography>
									{subscriptionFeatures?.length ? subscriptionFeatures?.map((feature) => {
										return (
											<Box key={feature?.id} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", border: "1px solid gray", px: "15px", py: 1, borderRadius: "10px", mb: 2 }}
											>
												<Typography sx={{ fontSize: "18px", mt: "10px", mb: "10px" }}>
													{feature?.title}: {feature?.featureValue}
												</Typography>
												<Box>
													<Button onClick={() => handleEditFeature(feature)} size="small" variant="text" sx={{ fontSize: "14px" }}>Edit</Button>/
													<Button onClick={() => handleDeleteFeature(feature?.id)} size="small" variant="text" sx={{ fontSize: "14px" }}>Delete</Button>
												</Box>
											</Box>
										);
									}) : (
										null
									)}
								</Box>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Container>
		</>
	);
};

export default ManageFeaturesTab;
