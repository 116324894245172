import { useEffect, useState } from "react"
import { useSelector } from "src/store/index";
import { createBotTheme, deleteBotTheme, fetchAllBotThemes, fetchBotTheme, updateBotTheme } from "src/store/botTheme/actions";
import { useDispatch } from "src/store";
import { getAllCreditFeaturePolicies } from "src/store/creditFeaturePolicy/action";

const useBotThemes = () => {
  const dispatch = useDispatch();

  const {
    data: botThemes = []
  } = useSelector((state: any) => state?.BotThemeReducer?.BotThemesReducer);

  const {
    data: botThemeDetails = []
  } = useSelector((state: any) => state?.BotThemeReducer?.BotThemeReducer);

  const { data: creditFeaturePolicies = [] 
  } = useSelector((state) => state?.CreditFeaturePolicies?.CreditFeaturePolicies);


  const botThemeInitialState = {
    title:"",
    link:"",
    devPolicyId: "",
    prodPolicyId: ""
  }
  const [loadingStates, setLoadingStates] = useState({
    fetchAllBotThemes: false,
    fetchBotTheme: false,
    createBotTheme:false,
    deleteBotTheme:false,
    updateBotTheme: false,
    fetchAllPolicies: false
  });
  const [botThemeInput, setBotThemeInput] = useState(botThemeInitialState);
  const [updateBotThemeInput, setUpdateBotThemeInput] = useState(botThemeInitialState)
  const [selectedBotTheme, setSelectedBotTheme] = useState(null)
  const [isCreateBotThemeModal, setIsCreateBotThemeModal] = useState(false)
  const [isDeleteBotThemeModal, setIsDeleteBotThemeModal] = useState(false)
  const [isUpdateBotThemeModal, setIsUpdateBotThemeModal] = useState(false)
  const [isBotThemeDetailsModal, setIsBotThemeDetailsModal] = useState(false)

  const handleLoadingStates = (key: string, value: boolean) => {
    setLoadingStates({
      ...loadingStates,
      [key]: value,
    });
  };

  const handleBotThemeInput = (key: string, value: any) => {
    setBotThemeInput({
      ...botThemeInput,
      [key]: value,
    });
  };

  const handleUpdateBotThemeInput = (key: string, value: any) => {
    setUpdateBotThemeInput({
      ...updateBotThemeInput,
      [key]: value,
    });
  };

  const handleCloseCreateModal = () => {
    setBotThemeInput(botThemeInitialState)
    setIsCreateBotThemeModal(false)
  }

  const handleDeleteModal = (botTheme) => {
    setSelectedBotTheme(botTheme)
    setIsDeleteBotThemeModal(true)
  }

  const handleUpdateModal = (botTheme) => {
    setSelectedBotTheme(botTheme)
    setUpdateBotThemeInput({
      title:botTheme.title,
      link:botTheme.link,
      devPolicyId: botTheme.devPolicyId,
      prodPolicyId: botTheme.prodPolicyId
    })
    setIsUpdateBotThemeModal(true)
  }

  const handleCreateBotTheme = async () => {
    await dispatch(createBotTheme(handleLoadingStates, botThemeInput, setIsCreateBotThemeModal));
    setBotThemeInput(botThemeInitialState)
  }
  
  const handleFetchBotTheme = async (id: string) =>{
    setIsBotThemeDetailsModal(true)
    dispatch(fetchBotTheme(id, handleLoadingStates))
  }

  const handleDeleteBotTheme =  () => 
  {
     dispatch(deleteBotTheme(selectedBotTheme.id, handleLoadingStates, setIsDeleteBotThemeModal))
  }

  const handleUpdateBotTheme = () =>{
    dispatch(updateBotTheme(selectedBotTheme.id, updateBotThemeInput, handleLoadingStates, setIsUpdateBotThemeModal ))
  }

  useEffect(()=>{
    dispatch(fetchAllBotThemes(handleLoadingStates))
    if(creditFeaturePolicies?.data?.length === 0){
      dispatch(getAllCreditFeaturePolicies(setLoadingStates));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[dispatch])


  return{
    botThemes,
    loadingStates,
    botThemeInput,
    botThemeDetails,
    handleUpdateModal,
    handleDeleteModal,
    updateBotThemeInput,
    handleBotThemeInput,
    handleFetchBotTheme,
    handleUpdateBotTheme,
    handleCreateBotTheme,
    handleDeleteBotTheme,
    isCreateBotThemeModal,
    isDeleteBotThemeModal,
    isUpdateBotThemeModal,
    creditFeaturePolicies,
    isBotThemeDetailsModal,
    handleCloseCreateModal,
    setIsCreateBotThemeModal,
    setIsDeleteBotThemeModal,
    setIsUpdateBotThemeModal,
    setIsBotThemeDetailsModal,
    handleUpdateBotThemeInput,
  }
}

export default useBotThemes