import { Helmet } from "react-helmet-async";
import {
	Container,
	Grid,
	Box,
	TextField,
	Switch,
	Button,
} from "@mui/material";
import { useState } from "react";

const BasicInformationTab = (props) => {

	const [submitClicked, setSubmitClicked] = useState(false);


	const {
		metered = false,
		handleChange,
		subscriptionData,
		subscriptionPrice,
		isUpdateSubscription,
		handleSubscriptionSave,
		handleSubscriptionPrice,
	} = props;

	const label = { inputProps: { 'aria-label': 'Switch demo' } };
	return (
		<>
			<Helmet>
				<title>APEXE3 Revolutions</title>
			</Helmet>
			<Container>
				<Box>
					<Grid container mt={3} spacing={4}>
						<Grid xs={7} item>
							<TextField
							label="Title"
								type="text"
								variant="outlined"
								sx={{ width: "100%", mb:3 }}
								value={subscriptionData?.title}
								onChange={(e) => handleChange('title', e)}
							/>
							<TextField
								id="description"
								label="Description"
								multiline
								rows={subscriptionData?.description?.length > 200 ? 9 : 4}
								value={subscriptionData?.description}
								onChange={(e) => handleChange('description', e)}
								variant="outlined"
								sx={{ width: "100%", mb:3 }}
							/>
							{metered ? (
								<>
									
										<TextField
										label="Price"
											type="number"
											id="outlined-number"
											onChange={(e) => {
												handleSubscriptionPrice(e)}}
											value={subscriptionPrice}
											sx={{ width: "100%", mb:3 }}
											error={
												submitClicked &&
												(!subscriptionPrice || subscriptionPrice < 1)
											}
											helperText={
												submitClicked &&
												subscriptionPrice < 1
													? "Price must be greater than 0."
													: ""
											}
											/>
									
								</>
							) : (null)}
							<Box>
								<Switch {...label}
									defaultChecked={subscriptionData?.displayOnMain}
									onChange={(e) => handleChange('displayOnMain', e)} />
								Display on main
							</Box>
							<Box sx={{ my: 3, display: "flex", justifyContent: "space-between" }}>
								<Box></Box>
								<Box>
									<Button onClick={()=>{
										setSubmitClicked(true)
										if (!metered || subscriptionPrice > 0) {
											handleSubscriptionSave();
									}
										}} variant="contained" size="large" sx={{borderRadius:"10px"}}>
										{isUpdateSubscription ? 'Update' : 'Save'}
									</Button>
								</Box>
							</Box>
						</Grid>
					</Grid>

				</Box>
			</Container>
		</>
	);
};

export default BasicInformationTab;
