import { combineReducers } from "@reduxjs/toolkit";
import SubscriptionsReducer from "./subscription/reducers";
import FeatureReducer from "./subscriptionFeature/reducer";
import CreditFeaturePoliciesReducer from "./creditFeaturePolicy/reducer";
import PolicyFeatureReducer from "./creditFeature/reducer";
import  BotThemeReducer  from "./botTheme/reducers";

const appReducer = combineReducers({
  Subscriptions: SubscriptionsReducer,
  Features: FeatureReducer,
  CreditFeaturePolicies: CreditFeaturePoliciesReducer,
  PolicyFeatureReducer: PolicyFeatureReducer,
  BotThemeReducer: BotThemeReducer,

});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;



