import { Helmet } from "react-helmet-async";
import {
  Container,
  Grid,
  Typography,
  Breadcrumbs,
  Link,
  Box,
  Button,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import ChevronRightIcon from "src/icons/ChevronRight";
import CreditFeaturePolicyTable from "src/components/dashboard/creditFeaturePolicy/CreditFeaturePolicyTable";
import useCreditsFeaturePolicy from "src/hooks/useCreditsFeaturePolicy";

const CreditsFeaturePolicyPage = () => {
  const {
    handleEdit,
    loadingStates,
    creditFeaturePolicies,
    handleDeleteCreditFeaturePolicy,
    handleActivateCreditFeaturePolicy,
    handleDeactivateCreditFeaturePolicy,
    handleSetCreditFeaturePolicyAsDefault
  } = useCreditsFeaturePolicy();
  return (
    <>
      <Helmet>
        <title>APEXE3 Revolutions</title>
      </Helmet>
      <Container>
        <Grid position="relative" height="100vh" sx={{ py: 4, mt: 8 }}>
          <Grid item sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              <Typography color="textPrimary" variant="h5">
                Credit Features Policy Management
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/"
                  variant="subtitle2"
                >
                  Overview
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  Credit Feature Policy
                </Typography>
              </Breadcrumbs>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "right",
                gap: 2,
                mt: 3,
              }}
            >
              <Button
                size="small"
                variant="outlined"
                sx={{ fontSize: "14px", borderRadius: "10px" }}
                href="/credit-feature-policy-form"
              >
                Create Credit Feature Policy
              </Button>
            </Box>
          </Grid>
          <Grid item>
            <CreditFeaturePolicyTable
              creditFeaturePolicies={creditFeaturePolicies}
              handleDeleteCreditFeaturePolicy={handleDeleteCreditFeaturePolicy}
              handleEdit={handleEdit}
              loadingStates={loadingStates}
              handleActivateCreditFeaturePolicy={handleActivateCreditFeaturePolicy}
              handleDeactivateCreditFeaturePolicy={handleDeactivateCreditFeaturePolicy}
              handleSetCreditFeaturePolicyAsDefault={handleSetCreditFeaturePolicyAsDefault}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CreditsFeaturePolicyPage;
