import { combineReducers } from "@reduxjs/toolkit";
import { 
  FETCH_ALL_BOT_THEMES_END,
  FETCH_ALL_BOT_THEMES_RECIEVES,
  FETCH_ALL_BOT_THEMES_START,
  FETCH_SINGLE_BOT_THEME_END,
  FETCH_SINGLE_BOT_THEME_RECIEVES,
  FETCH_SINGLE_BOT_THEME_START
} from "./action-types";

export const BotThemesReducer = (
  state: { loading: boolean; data: any } = {
    loading: false,
    data: { data: [] },
  },
  { type, data, error }
) => {
  switch (type) {
    case FETCH_ALL_BOT_THEMES_START: {
      return {
        loading: true,
        data: [],
        error: "",
      };
    }
    case FETCH_ALL_BOT_THEMES_RECIEVES: {
      return {
        loading: false,
        data,
        error: "",
      };
    }
    case FETCH_ALL_BOT_THEMES_END: {
      return {
        loading: false,
        data: [],
        error,
      };
    }
    default:
      return state;
  }
}

export const BotThemeReducer = (
  state: { loading: boolean; data: any } = {
    loading: false,
    data: { data: [] },
  },
  { type, data, error }
) => {
  switch (type) {
    case FETCH_SINGLE_BOT_THEME_START: {
      return {
        loading: true,
        data: [],
        error: "",
      };
    }
    case FETCH_SINGLE_BOT_THEME_RECIEVES: {
      return {
        loading: false,
        data,
        error: "",
      };
    }
    case FETCH_SINGLE_BOT_THEME_END: {
      return {
        loading: false,
        data: [],
        error,
      };
    }
    default:
      return state;
  }
}

export default combineReducers({
  BotThemesReducer,
  BotThemeReducer
});