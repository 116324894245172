import { useEffect, useState } from "react";
import { useSelector } from "src/store/index";
import toast from "react-hot-toast";
import { useDispatch } from "src/store";
import { activateCreditFeaturePolicy, createCreditFeaturePolicy, deleteFeature, getAllCreditFeaturePolicies, setCreditFeaturePolicyAsDefault, updateCreditFeaturePolicy } from "src/store/creditFeaturePolicy/action";
import { getCreditFeaturePolicy } from "src/store/creditFeaturePolicy/action";
import { deactivateCreditFeaturePolicy } from "src/store/creditFeaturePolicy/action";
import { CreditFeaturePolicyTypes } from "src/types/creditFeaturePolicy";
import { SyntheticEvent } from "react-draft-wysiwyg";
import { createPolicyFeature, deletePolicyFeature, getPolicyFeatures, updatePolicyFeature } from "src/store/creditFeature/action";


const useCreditsFeaturePolicy = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();
	const { data: creditFeaturePolicies = [] } = useSelector((state) => state?.CreditFeaturePolicies?.CreditFeaturePolicies);
  const { data: creditFeaturePolicy = [] } = useSelector((state) => state?.CreditFeaturePolicies?.CreditFeaturePolicy);
  const { data: createdCreditFeaturePolicy = [] } = useSelector((state) => state?.CreditFeaturePolicies?.CreateCreditFeaturePolicy);
  
  const { data: policyFeatures = [] } = useSelector((state) => state?.PolicyFeatureReducer?.GetPolicyFeatures);
  const [isUpdatePolicy, setIsUpdatePolicy] = useState(false)
  
  const policyFeatureInitialState = {
    featurePolicyId: creditFeaturePolicy?.data?.length === 0 ?  createdCreditFeaturePolicy?.id : creditFeaturePolicy?.id,
		featureType:'INDEX_CREATE',
		creditUsage:0
	}

  const [creditFeaturePolicyInput, setCreditFeaturePolicyInput] = useState({
    title: "",
    description: ""
  })

  const [isTabsDisable , setIsTabsDisable] = useState(true)

  const [policyFeatureInput, setPolicyFeatureInput] = useState(policyFeatureInitialState)

  const [isUpdateFeature, setIsUpdateFeature] = useState(false)
  const [featureToUpdate, setFeatureToUpdate] = useState("")
  const [loadingStates, setLoadingStates] = useState({
    fetchAllPolicies: false,
  });

  const handleLoadingStates = (key: string, value: boolean) => {
    setLoadingStates({
      ...loadingStates,
      [key]: value,
    });
  };

  const handleCreditFeaturePolicyInput = (key: string, value: string ) => {
    setCreditFeaturePolicyInput({
      ...creditFeaturePolicyInput,
      [key]: value,
    });
  };

  const handlePolicyFeatureInput = (key: string, value: string|number) => {
    setPolicyFeatureInput({
      ...policyFeatureInput,
      [key]: value,
    });
  }

  const handleEdit = async (status: string, id:string) => {
    if (status === "A") {
      toast.error("Inactive your policy first", {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        },
        });
    }else{
      await dispatch(getCreditFeaturePolicy(id));
    }
  };

  const handleFeatureEdit = async ( id:string, featureType, creditUsage) => {
    setIsUpdateFeature(true)
    setFeatureToUpdate(id)
    setPolicyFeatureInput({
      featurePolicyId:creditFeaturePolicy?.id,
		featureType:featureType,
		creditUsage:creditUsage
    })

  };

  const [currentTab, setCurrentTab] = useState("Basic Information");

  const handleDeleteCreditFeaturePolicy = async(id: string) => {
    await dispatch(deleteFeature(id));
    dispatch(getAllCreditFeaturePolicies(handleLoadingStates));
  }

  const handleCreateCreditFeaturePolicy = async() => {
    const id = await dispatch(createCreditFeaturePolicy(creditFeaturePolicyInput))
    await dispatch(getCreditFeaturePolicy(id));
    setIsTabsDisable(false)
    handlePolicyFeatureInput("featurePolicyId",id )
  }

  const handleUpdateCreditFeaturePolicy = (id:string) => {
    dispatch(updateCreditFeaturePolicy(creditFeaturePolicyInput, id))
  }

  const handleActivateCreditFeaturePolicy = async(id: string) => {
    await dispatch(activateCreditFeaturePolicy(id))
    dispatch(getAllCreditFeaturePolicies(handleLoadingStates));
  }

  const handleDeactivateCreditFeaturePolicy = async(id: string) => {
    await dispatch(deactivateCreditFeaturePolicy(id))
    dispatch(getAllCreditFeaturePolicies(handleLoadingStates));
  }

  const handleSetCreditFeaturePolicyAsDefault = async(id: string) => {
    await dispatch(setCreditFeaturePolicyAsDefault(id))
    dispatch(getAllCreditFeaturePolicies(handleLoadingStates));
  }
  const handleCreatePolicyFeature = async() => {
    await dispatch(createPolicyFeature(policyFeatureInput))
    setPolicyFeatureInput(policyFeatureInitialState)
    await dispatch(getPolicyFeatures(creditFeaturePolicy?.id));
  }

  const handleDeletePolicyFeature = async(id: string) => {
    await dispatch(deletePolicyFeature(id))
    dispatch(getPolicyFeatures(creditFeaturePolicy?.id));
  }  

  const handleUpdatePolicyFeature = async() => {
    await dispatch(updatePolicyFeature( policyFeatureInput,featureToUpdate))
    setIsUpdateFeature(false)
    setPolicyFeatureInput(policyFeatureInitialState)
    dispatch(getPolicyFeatures(creditFeaturePolicy?.id));
  }  

	const handleTabChange = (event: SyntheticEvent, newTab: string) => {
		setCurrentTab(newTab);
	};


  function getDisplayValue(value: string): string {
		switch (value) {
			case CreditFeaturePolicyTypes.Indexes:
				return 'Indexes';
			case CreditFeaturePolicyTypes.Resources:
				return 'Resources';
			case CreditFeaturePolicyTypes.Queries:
				return 'Queries';
      case CreditFeaturePolicyTypes["Bot Creation"]:
        return 'Bot Creation';
        case CreditFeaturePolicyTypes["Bot Queries"]:
          return "Bot Queries";
			default:
				return 'Unknown';
		}
	}

  useEffect(() => {
		dispatch(getAllCreditFeaturePolicies(handleLoadingStates));
	}, [dispatch]);

  useEffect(()=>{
    let id = queryParams.get('id');
    if(id){
      setIsUpdatePolicy(true)
      handlePolicyFeatureInput("featurePolicyId", id)
      dispatch(getPolicyFeatures(id))
        setCreditFeaturePolicyInput((prevData) => ({
					...prevData,
					title: creditFeaturePolicy?.title,
					description: creditFeaturePolicy?.description,
				}));
        if(creditFeaturePolicy?.data?.length === 0){
          dispatch(getCreditFeaturePolicy(id));
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[creditFeaturePolicy])


  return {
    currentTab,
    handleEdit,
    isTabsDisable,
    setCurrentTab,
    loadingStates,
    isUpdatePolicy,
    policyFeatures,
    handleTabChange,
    isUpdateFeature,
    getDisplayValue,
    setIsTabsDisable,
    handleFeatureEdit,
    policyFeatureInput,
    creditFeaturePolicy,
    creditFeaturePolicies,
    creditFeaturePolicyInput,
    handlePolicyFeatureInput,
    handleUpdatePolicyFeature,
    handleDeletePolicyFeature,
    handleCreatePolicyFeature,
    handleCreditFeaturePolicyInput,
    handleDeleteCreditFeaturePolicy,
    handleCreateCreditFeaturePolicy,
    handleUpdateCreditFeaturePolicy,
    handleActivateCreditFeaturePolicy,
    handleDeactivateCreditFeaturePolicy,
    handleSetCreditFeaturePolicyAsDefault,
  }
}

export default useCreditsFeaturePolicy