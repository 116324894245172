import {
	FETCH_SUBSCRIPTION_FEATURES_START,
	FETCH_SUBSCRIPTION_FEATURES_RECIEVES,
	FETCH_SUBSCRIPTION_FEATURES_END,
	CREATE_SUBSCRIPTION_FEATURE_START,
	CREATE_SUBSCRIPTION_FEATURE_RECIEVES,
	CREATE_SUBSCRIPTION_FEATURE_END,
	UPDATE_SUBSCRIPTION_FEATURE_START,
	UPDATE_SUBSCRIPTION_FEATURE_RECIEVES,
	UPDATE_SUBSCRIPTION_FEATURE_END,
	DELETE_SUBSCRIPTION_FEATURE_START,
	DELETE_SUBSCRIPTION_FEATURE_RECIEVES,
	DELETE_SUBSCRIPTION_FEATURE_END,
} from "./action-types";

import { subscriptionFeatureServiceApi } from "src/__Api__/subscriptionFeatureServiceApi";
import { isString } from "lodash";
import { SubscriptionFeature } from "src/types/subscriptionFeature";

export const featureList = (id: string) => async (dispatch) => {	
	try {
		dispatch({ type: FETCH_SUBSCRIPTION_FEATURES_START });

		const result = await subscriptionFeatureServiceApi.findSubscriptionFeature(id);

		if (isString(result)) {
			dispatch({
				type: FETCH_SUBSCRIPTION_FEATURES_END,
				data: [],
				error: result,
			});
		} else {
			dispatch({
				type: FETCH_SUBSCRIPTION_FEATURES_RECIEVES,
				data: result,
				error: "",
			});
		}
	} catch (error) {
		dispatch({
			type: FETCH_SUBSCRIPTION_FEATURES_END,
			data: [],
			error,
		});
	}
}

export const createFeature = (body: SubscriptionFeature, setFeatureToDefault: any) => async (dispatch) => {
	try {
		dispatch({ type: CREATE_SUBSCRIPTION_FEATURE_START });

		const result = await subscriptionFeatureServiceApi.createSubscriptionFeature(body);

		if (result && result?.id) {
			dispatch({
				type: CREATE_SUBSCRIPTION_FEATURE_RECIEVES,
				data: result,
				error: "",
			});
			dispatch(featureList(result?.planId));
			setFeatureToDefault();
		} else {
			dispatch({
				type: CREATE_SUBSCRIPTION_FEATURE_END,
				data: [],
				error: result,
			});
		}
	} catch (error) {
		dispatch({
			type: CREATE_SUBSCRIPTION_FEATURE_END,
			data: [],
			error,
		});
	}
}

export const updateFeature = (body: SubscriptionFeature, id: string, setFeatureToDefault: any, setIsUpdateFeature: any) => async (dispatch) => {
	try {
		dispatch({ type: UPDATE_SUBSCRIPTION_FEATURE_START });

		const result = await subscriptionFeatureServiceApi.updateSubscriptionFeature(body, id);

		if (result && result?.id) {
			dispatch({
				type: UPDATE_SUBSCRIPTION_FEATURE_RECIEVES,
				data: result,
				error: "",
			});
			dispatch(featureList(body?.planId));
			setFeatureToDefault();
			setIsUpdateFeature(false);
		} else {
			dispatch({
				type: UPDATE_SUBSCRIPTION_FEATURE_END,
				data: [],
				error: result,
			});
		}
	} catch (error) {
		dispatch({
			type: UPDATE_SUBSCRIPTION_FEATURE_END,
			data: [],
			error,
		});
	}

};

export const deleteFeature = (id: string, subscriptionId: string) => async (dispatch) => {
	try {
		dispatch({ type: DELETE_SUBSCRIPTION_FEATURE_START });

		const result = await subscriptionFeatureServiceApi.deleteSubscriptionFeature(id);

		if (result === 'done') {
			dispatch({
				type: DELETE_SUBSCRIPTION_FEATURE_RECIEVES,
				data: result,
				error: "",
			});
			dispatch(featureList(subscriptionId));
		} else {
			dispatch({
				type: DELETE_SUBSCRIPTION_FEATURE_END,
				data: [],
				error: result,
			});
		}
	} catch (error) {
		dispatch({
			type: DELETE_SUBSCRIPTION_FEATURE_END,
			data: [],
			error,
		});
	}

};
