import axios from 'axios';
import { Team } from 'src/types/team';
import { authenticationConfig } from 'src/config';

/**
 * 
 * Responsible for all portfolio related calls
 * 
 */
class EntityServiceAPI {


	async fetchUsersForTeam(teamId) {

		try {



		} catch (e) {
			console.log('Problem fetching users for team ', teamId);
			return [];
		}


	}

	async fetchBalanceForUser(ownerId) {

		try {

			if (ownerId != null) {

				let response = await axios.get('/entity/balance/user/' + ownerId);

				if (response != null && response.data) {
					return response.data;
				} else {
					console.log('Could not get balance for user ' + ownerId);
					return null;
				}


			} else {
				console.log('Could not get balance for user ' + ownerId);
				return null;
			}


		} catch (e) {
			console.log('Could not fetch balance for ' + ownerId);
		}
	}

	async fetchOverallROIAndRankForUser(ownerId) {

		try {

			if (ownerId != null) {

				let response = await axios.get('/entity/usersOveralROIAndRank/user/' + ownerId);

				if (response != null && response.data) {
					return response.data;
				} else {
					console.log('Could not get overall ROI/RANK for user ' + ownerId);
					return null;
				}


			} else {
				console.log('Could not get overall ROI/RANK for user ' + ownerId);
				return null;
			}


		} catch (e) {
			console.log('Could not fetch overal ROI/RANK for ' + ownerId);
		}
	}


	async fetchOverallROIAndRankForTeam(teamId) {

		try {

			if (teamId != null) {

				let response = await axios.get('/entity/teamsOveralROIAndRank/team/' + teamId);

				if (response != null && response.data) {
					return response.data;
				} else {
					console.log('Could not get overall ROI/RANK for team ' + teamId);
					return null;
				}


			} else {
				console.log('Could not get overall ROI/RANK for user ' + teamId);
				return null;
			}


		} catch (e) {
			console.log('Could not fetch overal ROI/RANK for ' + teamId);
		}
	}





	async sharePortfolioWithTeam(ownerId, portfolioId, teamId) {

		try {


			console.log("SHARING WITH TEAM", ownerId, portfolioId, teamId);
			if (ownerId && portfolioId && teamId) {
				let response = await axios.post('/entity/portfolio/team/' + ownerId + '/' + portfolioId + '/' + teamId);
				console.log('Sharing Response ', response);
				console.log("SHARED", ownerId, portfolioId, teamId);
				return response.data;
			} else {

				console.log('could not post');
			}

		} catch (e) {
			console.log(e);
		}


	}


	async unSharePortfolioWithTeam(ownerId, portfolioId, teamId) {

		try {

			console.log("UNSHARING WITH TEAM", ownerId, portfolioId, teamId);
			if (ownerId && portfolioId && teamId) {
				let response = await axios.delete('/entity/portfolio/team/' + ownerId + '/' + portfolioId + '/' + teamId);
				console.log(response);
				console.log("UNSHARED", ownerId, portfolioId, teamId);
			} else {

				console.log('could not post');
			}

		} catch (e) {
			console.log(e);
		}


	}


	/**
	 * 
	 * 
	 * TODO - THIS CALL NEEDS TO BE PROTECTED!!!!
	 * @param exchange
	 * @returns 
	 */
	async fetchUserProfile() {
		try {

			const { baseUrl } = authenticationConfig();
			let url = `${baseUrl}/users/me`;
			// await fetch('/instruments/'+exchange).then((resp) => resp.json()).then((data) => symbolsForExchange=data);
			// ToDo /me call should be placed here
			let response = await axios.get(url);
			if (response != null && response.data != null) {

				return response.data;
			} else { }


			return null;


		} catch (e) {
			console.log(e)
			return null;
		}


	}


	/**
	 * 
	 * @param userId   
	 */
	async fetchTransactionsForUser(userId: string) {
		try {

			let response = await axios.get('/entity/transaction/user/' + userId);


			if (response != null && response.data != null) {

				return response.data;
			} else { }

			return null;

		} catch (e) {
			console.log('Could not fetch transactions for user ' + userId);
		}

	}

	/**
	 * 
	 * @param portfolioId   
	 */
	async fetchTransactionsForPortfolio(portfolioId: string) {
		try {

			let response = await axios.get('/entity/transaction/portfolio/' + portfolioId);


			if (response != null && response.data != null) {

				return response.data;
			} else { }

			return null;

		} catch (e) {
			console.log('Could not fetch transactions for user ' + portfolioId);
		}


	}

	/**
	 *
	 * @returns
	 */
	async fetchAllTeams() {
		try {

			const response = await axios.get('/entity/team/all-teams/');

			if (response != null && response.data != null) {

				return response.data;
			}

			return null;

		} catch (e) {
			console.log(e)
			return null;
		}
	}

	/**
	 *
	 * @param teamId
	 * @returns
	 */
	async fetchUsersByTeamId(teamId: string) {
		try {
			const response = await axios.get('/entity/team/users/' + teamId)

			if (response != null && response.data != null) {
				return response.data;
			}

			return null;

		} catch (e) {
			console.log(e)
			return null;
		}
	}

	/**
	 *
	 * @returns
	 */
	async fetchOrganisationMembers() {
		try {

			const response = await axios.get('/entity/user/all-users');

			if (!response?.data) {
				return null;
			}

			return response.data;


		} catch (e) {
			console.log(e)
			return null;
		}
	}

	/**
	 * /team
	 * @param team
	 */
	async createTeam(team: Team) {
		try {
			if (team == null) {
				// TODO: Better validation?
				return null;
			}

			const response = await axios.post('/entity/team', team);

			return response;

		} catch (e) {
			console.log(e)
		}
	}

	/**
	 * /team
	 * @param team
	 */
	async updateTeam(team: Team) {
		try {
			if (team == null) {
				throw new Error('Team cannot be empty');
			}

			if (!team.name) {
				throw new Error('Team name cannot be empty');
			}

			const response = await axios.put('/entity/team', team);

			return response;

		} catch (e) {
			console.log(e)
		}
	}

	/**
	 *
	 * @param userId
	 * @parama teamId
	 */
	async associateUserWithTeam(userId: string, teamId: string): Promise<any> {

		try {

			const response = await axios.post(`/entity/team/${teamId}/${userId}`);

			return response;

		} catch (e) {
			console.log('could not share portfolio with team', e);
		}

	}

	/**
	 *
	 * @param portfolioId
	 * @param teamId
	 */
	async removeUserFromTeam(userId: string, teamId: string): Promise<any> {

		try {
			const response = await axios.delete(`/entity/team/${teamId}/${userId}`);

			return response;

		} catch (e) {
			console.log('could not share portfolio with team', e);
		}

	}


}


export const entityServiceAPI = new EntityServiceAPI();
