import {
	FETCH_SUBSCRIPTIONS_LIST_START,
	FETCH_SUBSCRIPTIONS_LIST_RECIEVES,
	FETCH_SUBSCRIPTIONS_LIST_END,
	CREATE_SUBSCRIPTIONS_START,
	CREATE_SUBSCRIPTIONS_RECIEVES,
	CREATE_SUBSCRIPTIONS_END,
	UPDATE_SUBSCRIPTIONS_START,
	UPDATE_SUBSCRIPTIONS_RECIEVES,
	UPDATE_SUBSCRIPTIONS_END,
	DELETE_SUBSCRIPTIONS_START,
	DELETE_SUBSCRIPTIONS_RECIEVES,
	DELETE_SUBSCRIPTIONS_END,
	Activate_SUBSCRIPTIONS_START,
	Activate_SUBSCRIPTIONS_RECIEVES,
	Activate_SUBSCRIPTIONS_END,
	Deactivate_SUBSCRIPTIONS_START,
	Deactivate_SUBSCRIPTIONS_RECIEVES,
	Deactivate_SUBSCRIPTIONS_END,
} from "./action-types";

import { subscriptionServiceApi } from "src/__Api__/subscriptionServiceApi";
import { isString } from "lodash";
import { CreateFreeSubscription, CreateMeteredSubscription, UpdateSubscription } from "src/types/subscription";


export const subscriptionList = (handleLoadingStates: any) => async (dispatch) => {
	try {
		handleLoadingStates('fetchAllPlans', true)
		dispatch({ type: FETCH_SUBSCRIPTIONS_LIST_START });

		const result = await subscriptionServiceApi.fetchAllSubscriptions();
		handleLoadingStates('fetchAllPlans', false)
		if (isString(result)) {
			dispatch({
				type: FETCH_SUBSCRIPTIONS_LIST_END,
				data: [],
				error: result,
			});
		} else {
			dispatch({
				type: FETCH_SUBSCRIPTIONS_LIST_RECIEVES,
				data: result,
				error: "",
			});
		}
	} catch (error) {
		dispatch({
			type: FETCH_SUBSCRIPTIONS_LIST_END,
			data: [],
			error,
		});
	}
};

export const createFreeSubscription = (body: CreateFreeSubscription, setIsUpdateSubscription: any) => async (dispatch) => {
	try {
		dispatch({ type: CREATE_SUBSCRIPTIONS_START });

		const result = await subscriptionServiceApi.createOneTimeFreeSubscription(body);

		if (result && result?.id) {
			setIsUpdateSubscription(true);
			dispatch({
				type: CREATE_SUBSCRIPTIONS_RECIEVES,
				data: result,
				error: "",
			});
		} else {
			dispatch({
				type: CREATE_SUBSCRIPTIONS_END,
				data: [],
				error: result,
			});
		}
	} catch (error) {
		dispatch({
			type: CREATE_SUBSCRIPTIONS_END,
			data: [],
			error,
		});
	}
};

export const updateFreeSubscription = (body: UpdateSubscription, id: string) => async (dispatch) => {
	try {
		dispatch({ type: UPDATE_SUBSCRIPTIONS_START });

		const result = await subscriptionServiceApi.updateOneTimeFreeSubscription(body, id);

		if (isString(result)) {
			dispatch({
				type: UPDATE_SUBSCRIPTIONS_END,
				data: [],
				error: result,
			});
		} else {
			dispatch({
				type: UPDATE_SUBSCRIPTIONS_RECIEVES,
				data: result,
				error: "",
			});
		}
	} catch (error) {
		dispatch({
			type: UPDATE_SUBSCRIPTIONS_END,
			data: [],
			error,
		});
	}
};

export const createMeteredSubscription = (body: CreateMeteredSubscription, setIsUpdateSubscription: any) => async (dispatch) => {
	try {
		dispatch({ type: CREATE_SUBSCRIPTIONS_START });

		const result = await subscriptionServiceApi.createMeteredSubscription(body);

		if (result && result?.id) {
			setIsUpdateSubscription(true);
			dispatch({
				type: CREATE_SUBSCRIPTIONS_RECIEVES,
				data: result,
				error: "",
			});
		} else {
			dispatch({
				type: CREATE_SUBSCRIPTIONS_END,
				data: [],
				error: result,
			});
		}
	} catch (error) {
		dispatch({
			type: CREATE_SUBSCRIPTIONS_END,
			data: [],
			error,
		});
	}
};

export const updateMeteredSubscription = (body: UpdateSubscription, id: string) => async (dispatch) => {
	try {
		dispatch({ type: UPDATE_SUBSCRIPTIONS_START });

		const result = await subscriptionServiceApi.updateMeteredSubscription(body, id);

		if (isString(result)) {
			dispatch({
				type: UPDATE_SUBSCRIPTIONS_END,
				data: [],
				error: result,
			});
		} else {
			dispatch({
				type: UPDATE_SUBSCRIPTIONS_RECIEVES,
				data: result,
				error: "",
			});
		}
	} catch (error) {
		dispatch({
			type: UPDATE_SUBSCRIPTIONS_END,
			data: [],
			error,
		});
	}
};

export const activateSubscription = (id: string, handleLoadingStates: any) => async (dispatch) => {
	try {
		dispatch({ type: Activate_SUBSCRIPTIONS_START });

		const result = await subscriptionServiceApi.activateSubscription(id);

		if (result === "done") {
			dispatch({
				type: Activate_SUBSCRIPTIONS_RECIEVES,
				data: result,
				error: "",
			});
			dispatch(subscriptionList(handleLoadingStates));
		} else {
			dispatch({
				type: Activate_SUBSCRIPTIONS_END,
				data: [],
				error: result,
			});
		}
	} catch (error) {
		dispatch({
			type: Activate_SUBSCRIPTIONS_END,
			data: [],
			error,
		});
	}
};

export const deActivateSubscription = (id: string, handleLoadingStates:any) => async (dispatch) => {
	try {
		dispatch({ type: Deactivate_SUBSCRIPTIONS_START });

		const result = await subscriptionServiceApi.deActivateSubscription(id);

		if (result === "done") {
			dispatch({
				type: Deactivate_SUBSCRIPTIONS_RECIEVES,
				data: result,
				error: "",
			});

			dispatch(subscriptionList(handleLoadingStates));
		} else {			
			dispatch({
				type: Deactivate_SUBSCRIPTIONS_END,
				data: [],
				error: result,
			});
		}
	} catch (error) {
		dispatch({
			type: Deactivate_SUBSCRIPTIONS_END,
			data: [],
			error,
		});
	}
};

export const deleteSubscription = (id: string, handleLoadingStates: any) => async (dispatch) => {
	try {
		dispatch({ type: DELETE_SUBSCRIPTIONS_START });

		const result = await subscriptionServiceApi.deleteSubscription(id);

		if (result === 'done') {
			dispatch({
				type: DELETE_SUBSCRIPTIONS_RECIEVES,
				data: result,
				error: "",
			});
			dispatch(subscriptionList(handleLoadingStates));
		} else {
			dispatch({
				type: DELETE_SUBSCRIPTIONS_END,
				data: [],
				error: result,
			});
		}
	} catch (error) {
		dispatch({
			type: DELETE_SUBSCRIPTIONS_END,
			data: [],
			error,
		});
	}
};
