import { Helmet } from "react-helmet-async";
import {
	Container,
	Grid,
	Box,
	TextField,
	Button,
} from "@mui/material";

const BasicInformationTab = (props) => {

	const {
		creditFeaturePolicyInput,
		handleCreditFeaturePolicyInput,
		handleCreateCreditFeaturePolicy,
		isUpdatePolicy,
		creditFeaturePolicy,
		handleUpdateCreditFeaturePolicy,

	} = props;


	return (
		<>
			<Helmet>
				<title>APEXE3 Revolutions</title>
			</Helmet>
			<Container>
				<Box>
					<Grid container mt={3} spacing={4}>
						<Grid xs={7} item>
							<TextField
							label="Title"
								type="text"
								variant="outlined"
								sx={{ width: "100%", mb:3 }}
								value={creditFeaturePolicyInput?.title}
								onChange={(e) => handleCreditFeaturePolicyInput('title', e.target.value)
								}
							/>
							<TextField
								id="description"
								label="Description"
								multiline
								rows={creditFeaturePolicyInput?.description?.length > 200 ? 9 : 4}
								value={creditFeaturePolicyInput?.description}
								onChange={(e) => handleCreditFeaturePolicyInput('description', e.target.value)}
								variant="outlined"
								sx={{ width: "100%", mb:3 }}
							/>
							<Box sx={{ my: 3, display: "flex", justifyContent: "space-between" }}>
								<Box></Box>
								<Box>
									<Button onClick={()=>{
										if(isUpdatePolicy){
											handleUpdateCreditFeaturePolicy(creditFeaturePolicy?.id)
										}
										else{
											handleCreateCreditFeaturePolicy()
										}
										}} variant="contained" size="large" sx={{borderRadius:"10px"}}>
											
										{isUpdatePolicy ? 'Update' : 'Save'}
									</Button>
								</Box>
							</Box>
						</Grid>
					</Grid>

				</Box>
			</Container>
		</>
	);
};

export default BasicInformationTab;
